import { snackbar } from 'src/mui';

import { pluralize } from 'src/libs/finbits/Pluralize';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useRestoreEntry } from 'src/libs/finbits/Management/Entries/Restore';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { useOpenConfirmRestore } from 'src/features/entries/Dialogs';

type Entry = Pick<FinancialStatementEntry, 'entryType' | 'id'>;

type Props = {
  selecteds: BillPayable[];
  onSuccess?: () => void;
};

function buildEntriesIds(rows: Entry[]) {
  return rows.reduce<{ billsIds: string[]; receivablesIds: string[] }>(
    (acc, row) => {
      if (row.entryType === EntryType.BILL) {
        acc.billsIds.push(row.id);
      }
      if (row.entryType === EntryType.RECEIVABLE) {
        acc.receivablesIds.push(row.id);
      }

      return acc;
    },
    { billsIds: [], receivablesIds: [] }
  );
}

export default function useRestoreAction({ selecteds, onSuccess }: Props) {
  const confirmRestoreEntry = useOpenConfirmRestore();

  const { organizationId, companyId } = useCompanyParams();

  const { restoreEntry } = useRestoreEntry();

  function handleRestore() {
    restoreEntry(
      { organizationId, companyId, ...buildEntriesIds(selecteds) },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: pluralize(
              selecteds.length,
              'O lançamento foi restaurado com sucesso',
              'Os lançamentos foram restaurados com sucesso'
            ),
          });
          onSuccess?.();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao restaurar os lançamentos',
          });
        },
      }
    );
  }

  function openRestoreConfirmation() {
    confirmRestoreEntry({
      onConfirm: handleRestore,
    });
  }

  return { restore: openRestoreConfirmation };
}
