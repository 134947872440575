import { FormField } from 'src/mui/_scss';
import type { FormFieldProps } from 'src/mui/_scss';

import type { SuggestionLabelProp } from 'src/features/bills-to-pay/BillContent/TabPanels/InformationTab/SuggestionLabel/SuggestionLabel';
import PaymentMethodSelect from 'src/features/payments/PaymentMethodSelect';
import type { AdornmentSuggestionOverlapFn } from 'src/features/entries/Suggestions/InputAdornmentSuggestion/endAdornmentFn';

type Props = {
  suggestion?: SuggestionLabelProp;
  endAdornmentFn?: AdornmentSuggestionOverlapFn;
} & FormFieldProps;

export default function PaymentMethodField({
  defaultValue = null,
  suggestion,
  endAdornmentFn,
  ...rest
}: Props) {
  return (
    <FormField defaultValue={defaultValue} label="Meio de pagamento" {...rest}>
      {(field) => (
        <PaymentMethodSelect
          {...field}
          endAdornmentFn={endAdornmentFn}
          onChange={(_e, value) => field.onChange(value)}
        />
      )}
    </FormField>
  );
}
