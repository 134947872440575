import { Box } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { BankIcon } from 'src/mui/_scss';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { formatCNPJ } from 'src/libs/finbits/Documents';

import styles from './AccountDetails.module.scss';
type Props = {
  account: Account;
};

export default function AccountDetails({ account }: Props) {
  return (
    <aside className={styles.aside}>
      <Typography
        component="h2"
        color="black"
        variant="text-md"
        fontWeight="600"
      >
        Conta a ser conectada:
      </Typography>

      <BankIcon routingNumber={account.routingNumber} width={64} height={64} />

      <Box>
        <Typography
          color="grey.600"
          variant="text-xs"
          fontWeight="600"
          marginBottom={1}
        >
          Agência
        </Typography>

        <Typography color="grey.600" variant="text-sm">
          {account.branchNumber}
        </Typography>
      </Box>

      <Box>
        <Typography
          color="grey.600"
          variant="text-xs"
          fontWeight="600"
          marginBottom={1}
        >
          Conta
        </Typography>

        <Typography color="grey.600" variant="text-sm">
          {account.accountNumber}
        </Typography>
      </Box>

      {account.btgConnection?.ownerDocument && (
        <Box>
          <Typography
            color="grey.600"
            variant="text-xs"
            fontWeight="600"
            marginBottom={1}
          >
            CNPJ
          </Typography>

          <Typography color="grey.600" variant="text-sm">
            {formatCNPJ(account.btgConnection.ownerDocument)}
          </Typography>
        </Box>
      )}
    </aside>
  );
}
