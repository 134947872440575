import type { ReactNode } from 'react';

import { InputAdornment } from '@mui/material';

import SuggestionIcon from '../SuggestionIcon';

import styles from './InputAdornmentSuggestion.module.scss';

type Props = {
  fieldName?: string;
  children?: ReactNode;
  disabled?: boolean;
  type?: 'common' | 'overlap';
  showCustomIcon?: boolean;
};

export default function InputAdornmentSuggestion({
  fieldName,
  children,
  type = 'common',
  showCustomIcon = false,
  disabled = false,
}: Props) {
  if (typeof fieldName === 'undefined') return null;
  if (!children) return null;

  return (
    <InputAdornment position="end" className={styles.iconBox}>
      {type === 'common' && (
        <>
          <SuggestionIcon fieldName={fieldName} />
          <span className={styles.openIcon}>{children}</span>
        </>
      )}

      {type === 'overlap' &&
        (showCustomIcon && !disabled ? (
          <span>{children}</span>
        ) : (
          <>
            <SuggestionIcon fieldName={fieldName} />
          </>
        ))}
    </InputAdornment>
  );
}
