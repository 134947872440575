import type { ReactNode } from 'react';

import { Box } from '@mui/system';
import { Typography } from 'src/design-system/components';
import { CheckSquareIcon, IndentLastIcon, SquareIcon } from 'src/mui/_icons';

import styles from './AccountFeatureBadge.module.scss';

type Props = {
  children?: ReactNode;
  isAccountFeatureEnabled: boolean;
  nested?: true;
  title: string;
};

export default function AccountFeatureBadge({
  children,
  isAccountFeatureEnabled,
  nested,
  title,
}: Props) {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box display="flex">
        {nested && <IndentLastIcon className={styles.icon} />}

        {isAccountFeatureEnabled ? (
          <CheckSquareIcon className={styles.icon} />
        ) : (
          <SquareIcon className={styles.icon} />
        )}
      </Box>

      <Typography variant="text-sm" color="black">
        {title}
      </Typography>
      {children}
    </Box>
  );
}
