import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function IndentLastIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="alert-circle"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M8 3V8.2C8 9.88016 8 10.7202 8.32698 11.362C8.6146 11.9265 9.07354 12.3854 9.63803 12.673C10.2798 13 15.1198 13 16.8 13"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
