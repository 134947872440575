import { useMemo } from 'react';

import cn from 'classnames';
import type { SvgIconProps } from '@mui/material';
import { SparklesIcon } from 'src/mui/_icons';
import isArray from 'lodash/isArray';

import { Tooltip } from 'src/mui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import { useEntrySuggestionsContext } from '../EntrySuggestionsProvider';

import styles from './SuggestionIcon.module.scss';

type Variant = 'input' | 'tab';

type Props = SvgIconProps & {
  className?: string;
  fieldName: RegExp | string | string[];
  variant?: Variant;
};

export default function SuggestionIcon({
  fieldName,
  variant = 'input',
  className,
  ...rest
}: Props) {
  const fields = useMemo(
    () => (isArray(fieldName) ? fieldName : [fieldName]),
    [fieldName]
  );

  const { isSuggestionField } = useEntrySuggestionsContext();
  const { isEnabled: isSparkleForFieldSuggestionFromAiEnabled } =
    useExternalFeatureFlag(Feature.AI_AUTOFILL_SUGGESTIONS);

  if (
    fields.some((field) => isSuggestionField(field)) &&
    isSparkleForFieldSuggestionFromAiEnabled
  ) {
    return (
      <Tooltip
        className={cn(styles.icon, className)}
        title={
          variant === 'input' ? (
            <>
              Campo autopreenchido pela FinBot. <br />
              Por favor, revise e ajuste, se necessário.
            </>
          ) : (
            'Campos autopreenchidos pela FinBot'
          )
        }
      >
        <SparklesIcon
          fontSize="xsmall"
          aria-label="Autopreenchimento pela FinBot"
          {...rest}
        />
      </Tooltip>
    );
  }

  return null;
}
