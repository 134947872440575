import type { ReactNode } from 'react';

import { Button, Card } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { LinkExternalIcon } from 'src/mui/_icons';

import styles from './AutoPaymentCard.module.scss';

type Props = {
  buttonHref?: string;
  buttonLabel?: string;
  buttonTarget?: '_self' | '_blank' | '_parent' | '_top';
  children?: ReactNode;
  heading: string;
  onClick?: () => void;
  stepIndex: number;
};

export default function AutoPaymentCard({
  buttonHref,
  buttonLabel,
  buttonTarget = '_self',
  children,
  heading,
  onClick,
  stepIndex,
}: Props) {
  return (
    <Card variant="outlined" className={styles.box}>
      <Typography
        component="h3"
        variant="text-md"
        color="black"
        fontWeight="600"
      >
        <span className={styles.number}>{stepIndex}.</span>
        {heading}
      </Typography>

      {children}

      {buttonLabel && (
        <Button
          component={buttonHref ? 'a' : 'button'}
          variant="outlined"
          endIcon={<LinkExternalIcon />}
          onClick={onClick}
          className={styles.btn}
          href={buttonHref}
          target={buttonTarget}
        >
          {buttonLabel}
        </Button>
      )}
    </Card>
  );
}
