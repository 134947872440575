import { Typography } from 'src/design-system/components';

import styles from './Header.module.scss';

type Props = {
  title: string;
  Icon?: JSX.Element;
};

export default function Header({ Icon, title }: Props) {
  return (
    <Typography variant="text-sm" className={styles.title}>
      {title}
      {Icon}
    </Typography>
  );
}
