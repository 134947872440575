import type { FormFieldProps } from 'src/mui/_scss';
import { FormField } from 'src/mui/_scss';
import type { ControllerRenderProps } from 'react-hook-form';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { useCategories } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { CategorySuggestion } from 'src/libs/finbits/Management/Entries/types';
import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';

import SuggestionLabel from 'src/features/bills-to-pay/BillContent/TabPanels/InformationTab/SuggestionLabel';
import CategorySelect from 'src/features/categories/CategorySelect';
import type { AdornmentSuggestionOverlapFn } from 'src/features/entries/Suggestions/InputAdornmentSuggestion/endAdornmentFn';

export type Props = {
  type: BalanceType;
  suggestion?: CategorySuggestion | null;
  isLoadingSuggestion?: boolean;
  endAdornmentFn?: AdornmentSuggestionOverlapFn;
} & FormFieldProps;

export default function CategoriesField({
  type,
  suggestion,
  isLoadingSuggestion,
  defaultValue = null,
  endAdornmentFn,
  ...rest
}: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { categories, isLoading } = useCategories(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const filteredCategories = categories.filter(
    (category) => category.type === type && category.active
  );

  function handleChange(field: ControllerRenderProps, value: Category | null) {
    field.onChange?.(value?.id ?? null);
  }

  return (
    <FormField
      {...rest}
      defaultValue={defaultValue}
      label={(categoryId) => (
        <SuggestionLabel
          label="Categoria"
          suggestion={suggestion?.id === categoryId}
          isLoading={isLoadingSuggestion}
        />
      )}
    >
      {(field) => {
        const category =
          filteredCategories.find((category) => category.id === field.value) ??
          null;

        return (
          <CategorySelect<false, false>
            {...field}
            endAdornmentFn={endAdornmentFn}
            type={type}
            placeholder="Digite a categoria"
            value={category}
            loading={isLoading}
            onChange={(_e, value) => handleChange(field, value)}
          />
        );
      }}
    </FormField>
  );
}
