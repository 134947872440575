import type { MutableRefObject } from 'react';

import { Divider } from '@mui/material';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { Chip } from 'src/design-system/components';
import { DotsVerticalIcon } from 'src/mui/_icons';

import { Badge, Dropdown } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { redirectPathAfterEntriesImport } from 'src/libs/finbits/Bank/Statements';
import { useConciliationSuggestions } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import {
  WithAllowedPermission,
  useAllowedPermission,
} from 'src/libs/finbits/Permissions';

import { ConciliationSuggestionsDrawer } from 'src/features/entries/ConciliationSuggestionsDrawer';
import { IgnoredRecordsDrawer } from 'src/features/entries/IgnoredRecordsDrawer';
import ExportModal from 'src/features/entries/Modals/ExportModal';
import ImportScheduledEntryModal from 'src/features/entries/Modals/ImportScheduledEntryModal';
import useEntriesParams from 'src/features/entries/useEntriesParams';
import ImportOfxModal from 'src/features/ofx/ImportOfxModal';

import styles from './ButtonActions.module.scss';

type Props = {
  apiRef: MutableRefObject<GridApiPremium>;
};

export default function ButtonActions({ apiRef }: Props) {
  const openPortal = useOpenPortal();

  const {
    organizationId,
    companyId,
    selectedAccounts,
    selectedDateRange,
    accounts,
    isLoadingAccounts,
  } = useEntriesParams();

  const canAcceptSuggestions = useAllowedPermission({
    action: 'update',
    resource: 'entriesConciliationSuggestions',
  });

  const { suggestions } = useConciliationSuggestions(
    { organizationId, companyId },
    { enabled: canAcceptSuggestions }
  );

  const suggestionsCount = suggestions.length;

  function handleOpenExportModal() {
    openPortal(ExportModal, {
      organizationId,
      companyId,
      selectedAccounts,
      accounts,
      dateRange: selectedDateRange,
    });
  }

  function handleExportCsv() {
    apiRef.current.exportDataAsCsv();
  }

  function handleOpenImportScheduledEntryModal() {
    openPortal(ImportScheduledEntryModal, {
      accounts,
      isLoadingAccounts,
    });
  }

  function handleUploadSettled() {
    openPortal(ImportOfxModal, {
      organizationId,
      companyId,
      redirectPathAfterImport: redirectPathAfterEntriesImport,
    });
  }

  function handleExportExcel() {
    apiRef.current.exportDataAsExcel({
      columnsStyles: {
        date: { numFmt: 'dd/mm/yyyy' },
        relevantDate: { numFmt: 'MMM/yyyy' },
        amount: { numFmt: '"R$" #,##0.00;"R$" -#,##0.00' },
      },
    });
  }

  function handleOpenIgnoredRecordsDrawer() {
    openPortal(IgnoredRecordsDrawer);
  }

  function handleOpenConciliationSuggestionsDrawer() {
    openPortal(ConciliationSuggestionsDrawer, {
      suggestions,
    });
  }

  return (
    <Badge badgeContent={suggestionsCount} color="primary" showZero>
      <Dropdown
        className={styles.actions}
        aria-label="ações"
        variant="outlined"
        PopoverProps={{
          className: styles.popover,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        options={[
          <WithAllowedPermission
            key="openConciliationSuggestionsDrawer"
            permissions={{
              resource: 'entriesConciliationSuggestions',
              action: 'update',
            }}
          >
            <Dropdown.Option onClick={handleOpenConciliationSuggestionsDrawer}>
              Sugestões de conciliação
              <Chip
                label={suggestionsCount}
                size="small"
                className={styles.chip}
              />
            </Dropdown.Option>
          </WithAllowedPermission>,

          <Divider key="uploadsDivider" />,

          <WithAllowedPermission
            permissions={{
              resource: 'entries',
              action: 'import_spreadsheet',
            }}
            key="uploadScheduled"
          >
            <Dropdown.Option onClick={handleOpenImportScheduledEntryModal}>
              Importar lançamentos programados (.xlsx)
            </Dropdown.Option>
          </WithAllowedPermission>,

          <WithAllowedPermission
            permissions={{
              resource: 'bankStatement',
              action: 'create',
            }}
            key="uploadSettled"
          >
            <Dropdown.Option onClick={handleUploadSettled}>
              Importar lançamentos realizados (.ofx)
            </Dropdown.Option>
          </WithAllowedPermission>,

          <Divider key="downloadsDivider" />,

          <Dropdown.Option onClick={handleExportExcel} key="createExcel">
            Exportar lançamentos (.xlsx)
          </Dropdown.Option>,

          <Dropdown.Option onClick={handleExportCsv} key="createCsv">
            Exportar lançamentos (.csv)
          </Dropdown.Option>,

          <WithAllowedPermission
            permissions={{
              resource: 'statementExport',
              action: 'create',
            }}
            key="createExport"
          >
            <Dropdown.Option onClick={handleOpenExportModal}>
              Exportar lançamentos e anexos (.zip)
            </Dropdown.Option>
          </WithAllowedPermission>,

          <Divider key="drawersDivider" />,

          <Dropdown.Option
            onClick={handleOpenIgnoredRecordsDrawer}
            key="openDeletedDrawer"
          >
            Ver excluídos
          </Dropdown.Option>,
        ]}
      >
        <DotsVerticalIcon />
      </Dropdown>
    </Badge>
  );
}
