import { SuggestionField } from './type';

export const suggestionFieldNameDictionary = {
  [SuggestionField.CATEGORY_ID]: 'Categoria',
  [SuggestionField.ACCOUNT_ID]: 'Conta',
  [SuggestionField.AMOUNT]: 'Valor',
  [SuggestionField.DATE]: 'Data de pagamento',
  [SuggestionField.RELEVANT_DATE]: 'Competência',
  [SuggestionField.CONTACT_ID]: 'Contato',
  [SuggestionField.DESCRIPTION]: 'Descrição',
  [SuggestionField.NOTA_FISCAL_NUMBER]: 'Nota fiscal',
  [SuggestionField.NOTA_FISCAL_ISSUE_DATE]: 'Data de emissão',
  [SuggestionField.DUE_DATE]: 'Data de vencimento',
  [SuggestionField.COMMENTS]: 'Observação',
  [SuggestionField.PAYMENTS_DETAILS]: 'Detalhes de Pagamento',
  [SuggestionField.PAYMENT_DETAILS_PAYMENT_METHOD]: 'Método de pagamento',
  [SuggestionField.PAYMENT_DETAILS_DIGITABLE_LINE]: 'Linha digitável',
  [SuggestionField.PAYMENT_DETAILS_PIX_KEY]: 'Chave Pix',
  [SuggestionField.PAYMENT_DETAILS_PIX_TYPE]: 'Tipo do Pix',
  [SuggestionField.PAYMENT_DETAILS_ACCOUNT_DOCUMENT]: 'Documento',
  [SuggestionField.PAYMENT_DETAILS_ACCOUNT_TYPE]: 'Tipo da Conta',
  [SuggestionField.PAYMENT_DETAILS_ACCOUNT_NUMBER]: 'Conta (com o dígito)',
  [SuggestionField.PAYMENT_DETAILS_BRANCH_NUMBER]: 'Agência',
  [SuggestionField.PAYMENT_DETAILS_ROUTING_NUMBER]: 'Banco',
  [SuggestionField.PAYMENT_DETAILS_BANK_NAME]: 'Banco',
  [SuggestionField.PAYMENT_DETAILS_PAYEE_NAME]: 'Recebedor',
};
