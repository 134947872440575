import { useState } from 'react';

import type { DatePickerProps } from '@mui/x-date-pickers-pro';
import type { FormFieldProps } from 'src/mui/_scss';
import { FormField } from 'src/mui/_scss';
import { CalendarIcon } from 'src/mui/_icons';

import { DatePicker } from 'src/mui';

import InputAdornmentSuggestion from 'src/features/entries/Suggestions/InputAdornmentSuggestion';

type Props = {
  DatePickerProps?: DatePickerProps<Date>;
  showSuggestionIcon?: boolean;
} & FormFieldProps;

export default function DateField({
  defaultValue = null,
  DatePickerProps,
  showSuggestionIcon,
  ...rest
}: Props) {
  const [open, setOpen] = useState(false);

  return (
    <FormField {...rest} defaultValue={defaultValue}>
      {(field) => (
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            ...DatePickerProps?.slotProps,
            textField: {
              error: field.error,
              id: field.id,
              ...(rest.placeholder ? { placeholder: rest.placeholder } : {}),
            },
          }}
          {...(showSuggestionIcon && {
            slots: {
              inputAdornment: () => (
                <InputAdornmentSuggestion fieldName={rest.name}>
                  <CalendarIcon
                    aria-label="Abrir calendário"
                    fontSize="small"
                    onClick={() => setOpen(true)}
                  />
                </InputAdornmentSuggestion>
              ),
            },
          })}
          {...field}
          {...DatePickerProps}
        />
      )}
    </FormField>
  );
}
