import t from 'zod';

import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { EntryClassificationsDecoder } from 'src/libs/finbits/Classifications/types';
import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import {
  AvailableActions,
  EntrySituation,
} from 'src/libs/finbits/Management/Entries/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import type { Receivable } from 'src/libs/finbits/Receivables/types';

import { EditableFields } from 'src/features/EntryForm/types';

export enum EntryType {
  BILL = 'bill',
  RECEIVABLE = 'receivable',
  FINANCIAL_ENTRY = 'financial_entry',
}

export enum ConnectionProviderType {
  PLUGGY = 'pluggy',
  BTG = 'btg',
  ASAAS = 'asaas',
  MANUAL = 'manual',
}

const SummaryAccountDecoder = t.object({
  account: AccountDecoder,
  creditAmount: t.number(),
  debitAmount: t.number(),
  finalBalance: t.object({
    amount: t.number(),
    type: t.nativeEnum(BalanceType),
  }),
  inconsistency: t.object({
    amount: t.number(),
    type: t.nativeEnum(BalanceType),
  }),
  connectionProvider: t.nativeEnum(ConnectionProviderType),
  balancePending: t.boolean(),
});

export type SummaryAccount = t.TypeOf<typeof SummaryAccountDecoder>;

export const FinancialStatementEntryDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  description: t.string(),
  categoryId: t.string().nullable(),
  category: CategoryDecoder.nullish(),
  notaFiscalId: t.string().nullable(),
  contactId: t.string().nullable(),
  contact: ContactDecoder.nullish(),
  transactionId: t.string().nullable(),
  accountId: t.string(),
  account: AccountDecoder.optional(),
  relevantDate: t.string().nullable(),
  comments: t.string().nullable(),
  notaFiscalNumber: t.string().nullable(),
  notaFiscalIssueDate: t.string().nullable(),
  dueDate: t.string().nullable(),
  attachments: AttachmentDecoder.array().optional(),
  amountType: t.nativeEnum(BalanceType),
  date: t.string(),
  classifications: EntryClassificationsDecoder.array(),
  isSplit: t.boolean().nullable(),
  availableActions: t.nativeEnum(AvailableActions).array(),
  currentSituation: t.nativeEnum(EntrySituation),
  editableFields: t.nativeEnum(EditableFields).array(),
  entryType: t.nativeEnum(EntryType),
  unfilledContextFields: t.string().array().nullable(),
});

export const FinancialStatementEntriesSummaryDecoder = t.object({
  summary: t.object({
    initialBalance: t.object({
      amount: t.number(),
      type: t.nativeEnum(BalanceType),
    }),
    creditAmount: t.number(),
    debitAmount: t.number(),
    finalBalance: t.object({
      amount: t.number(),
      type: t.nativeEnum(BalanceType),
    }),
    billAmount: t.number(),
    receivableAmount: t.number(),
    projectedBalance: t.object({
      amount: t.number(),
      type: t.nativeEnum(BalanceType),
    }),
    totalCredit: t.number(),
    totalDebit: t.number(),
  }),
  accounts: SummaryAccountDecoder.array(),
  entries: FinancialStatementEntryDecoder.array(),
});

export const FinancialStatementEntriesDecoder = t.object({
  entries: FinancialStatementEntryDecoder.array(),
});

export type FinancialStatementEntries = t.TypeOf<
  typeof FinancialStatementEntriesDecoder
>;

export type FinancialStatementEntriesSummary = t.TypeOf<
  typeof FinancialStatementEntriesSummaryDecoder
>;

export type FinancialStatementEntry = t.TypeOf<
  typeof FinancialStatementEntryDecoder
>;

export type GetParams = {
  organizationId?: string;
  companyId?: string;
  accountsIds: string[];
  startDate?: string;
  endDate?: string;
  groupSplits?: boolean;
};

//TODO: renomear esse type pos migracao de scheduled
export type ScheduledStatementEntry = BillPayable | Receivable;

export type GetDeletedParams = {
  organizationId?: string;
  companyId?: string;
  accountsIds: string[];
  endDate: string;
  startDate: string;
};

export type GetOverdueEntriesParams = {
  organizationId: string;
  companyId: string;
};
