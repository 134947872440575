import { Button } from '@mui/material';
import { CurrencyDollarCircleIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import { BillTab } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';
import { useTabPanelsContext } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';

import styles from './RecurrenceButton.module.scss';

export default function RecurrenceButton() {
  const { setSelectedBillTab, disabledTabs } = useTabPanelsContext();

  const isDisabled = Boolean(disabledTabs?.recurrence);

  return (
    <Button
      aria-label="Recorrência"
      variant="outlined"
      className={styles.recurrenceButton}
      disabled={isDisabled}
      onClick={() => setSelectedBillTab(BillTab.RECURRENCE)}
    >
      <Tooltip
        classNames={styles.tooltip}
        title={
          isDisabled
            ? 'Não é possível criar recorrência para um lançamento vinculado.'
            : 'Recorrência'
        }
        style={{ pointerEvents: 'auto' }}
      >
        <CurrencyDollarCircleIcon />
      </Tooltip>
    </Button>
  );
}
