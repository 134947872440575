import { LoadingButton } from '@mui/lab';
import { SendToPaymentIcon } from 'src/mui/_icons';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import useWaitingPaymentAction from 'src/features/bills-to-pay/Actions/send-to-payment-action/hooks/useWaitingPaymentAction';

import type { Action } from '../../types';

export default function SendToWaitingPaymentButton({
  selecteds,
  onSuccess,
}: Action) {
  const { sendToWaitingPayment, isLoading } = useWaitingPaymentAction({
    selecteds,
    onSuccess,
  });

  const isEmpty = selecteds.length === 0;

  return (
    <WithAllowedPermission
      permissions={{
        resource: 'waitingPayments',
        action: 'create',
      }}
    >
      <LoadingButton
        variant="text"
        disabled={isEmpty}
        startIcon={<SendToPaymentIcon />}
        onClick={sendToWaitingPayment}
        aria-label="Enviar para pagamento"
        loading={isLoading}
      >
        Enviar para pagamento
      </LoadingButton>
    </WithAllowedPermission>
  );
}
