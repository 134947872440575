import type { ComponentType, ReactNode } from 'react';

import merge from 'lodash/merge';
import type { StackProps, SvgIconProps } from '@mui/material';
import { DialogTitle, IconButton, Stack } from '@mui/material';
import { CloseIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { FocusIcon } from 'src/mui';

import { closeButton, dialogTitle, header, sizes } from './Header.sx';

export type DialogHeaderSize = 'small' | 'medium';

type Props = {
  icon?: ComponentType<SvgIconProps>;
  title: ReactNode;
  description?: ReactNode;
  size?: DialogHeaderSize;
  onClose: () => void;
} & Omit<StackProps, 'title'>;

export default function Header({
  icon: Icon,
  description,
  title,
  sx,
  size = 'medium',
  onClose,
  ...rest
}: Props) {
  return (
    <Stack
      direction="row"
      alignItems={Icon ? 'flex-start' : 'center'}
      sx={merge(header, sizes[size], sx)}
      {...rest}
    >
      {Icon && (
        <FocusIcon.Square size="lg">
          <Icon fontSize="medium" />
        </FocusIcon.Square>
      )}
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        flex={1}
      >
        <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
        {description && (
          <Typography color="text.secondary">{description}</Typography>
        )}
      </Stack>
      <IconButton aria-label="close" onClick={onClose} sx={closeButton}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}
