import { Box, Card, CardContent, Divider, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';

import {
  format,
  isFuture,
  isToday,
  startOfYesterday,
} from 'src/libs/finbits/Date';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import type { FinancialStatementEntriesSummary } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import BalanceAmount from './BalanceAmount';
import styles from './EntriesSummary.module.scss';
import Header from './Header';
import MoneyBox from './MoneyBox';

export type Props = {
  data: FinancialStatementEntriesSummary;
  selectedStartDate: Date;
  selectedEndDate: Date;
};

function getCurrentBalanceDate(endDate: Date) {
  if (isToday(endDate) || isFuture(endDate)) {
    return startOfYesterday();
  }
  return endDate;
}

export default function EntriesSummary({
  data,
  selectedStartDate,
  selectedEndDate,
}: Props) {
  const {
    accounts,
    summary: {
      billAmount,
      creditAmount,
      debitAmount,
      finalBalance,
      initialBalance,
      projectedBalance,
      receivableAmount,
      totalCredit,
      totalDebit,
    },
  } = data;

  const cashIn = receivableAmount + creditAmount;
  const cashOut = billAmount + debitAmount;

  const hasInconsistency = accounts.some(
    ({ inconsistency }) => inconsistency.amount > 0
  );

  const someBalancePending = accounts.some(
    ({ balancePending }) => balancePending
  );

  const currentBalanceDate = getCurrentBalanceDate(selectedEndDate);

  const { isEnabled: isNewEntriesSummaryEnabled } = useExternalFeatureFlag(
    Feature.NEW_ENTRIES_SUMMARY
  );

  return (
    <>
      {isNewEntriesSummaryEnabled ? (
        <Box
          display="grid"
          gridTemplateRows="repeat(4, 1fr)"
          gap={{ xs: 2, xl: 6 }}
          paddingLeft={6}
          height="100%"
          data-testid="entries-summary"
        >
          <Card className={styles.card} variant="outlined">
            <CardContent>
              <Header title="Saldo inicial no período" />

              <MoneyBox text={`Em ${format(currentBalanceDate, 'dd/MM/yyyy')}`}>
                <BalanceAmount
                  amount={initialBalance.amount}
                  amountType={initialBalance.type}
                  hasInconsistency={hasInconsistency}
                  someBalancePending={someBalancePending}
                  accounts={accounts}
                  showIconInfo={false}
                />
              </MoneyBox>
            </CardContent>
          </Card>

          <Card className={styles.card} variant="outlined">
            <CardContent>
              <Header
                title="Entradas"
                Icon={<ArrowCircleUpIcon color="success" />}
              />

              <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
                <MoneyBox
                  text="Previstas"
                  amount={totalCredit}
                  textColor="grey.500"
                />
                <MoneyBox text="Realizadas" amount={creditAmount} />
              </Box>
            </CardContent>
          </Card>

          <Card className={styles.card} variant="outlined">
            <CardContent>
              <Header
                title="Saídas"
                Icon={<ArrowCircleDownIcon color="error" />}
              />

              <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
                <MoneyBox
                  text="Previstas"
                  amount={totalDebit}
                  textColor="grey.500"
                />
                <MoneyBox text="Realizadas" amount={debitAmount} />
              </Box>
            </CardContent>
          </Card>

          <Card className={styles.card} variant="outlined">
            <CardContent>
              <Header title="Saldo ao final do período" />

              <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={4}>
                <MoneyBox text="Previsto">
                  <BalanceAmount
                    amount={projectedBalance.amount}
                    amountType={projectedBalance.type}
                    hasInconsistency={hasInconsistency}
                    someBalancePending={someBalancePending}
                    accounts={accounts}
                    textColor="grey.500"
                    showIconInfo={false}
                  />
                </MoneyBox>

                <MoneyBox text="Realizado">
                  <BalanceAmount
                    amount={finalBalance.amount}
                    amountType={finalBalance.type}
                    hasInconsistency={hasInconsistency}
                    someBalancePending={someBalancePending}
                    accounts={accounts}
                    showIconInfo={false}
                  />
                </MoneyBox>
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Stack direction="row">
          <Stack>
            <Stack direction="row" alignItems="center" mb={2}>
              <Typography variant="text-xs" fontWeight={600}>
                Projetado de {format(selectedStartDate, 'dd/MM/yyyy')} a{' '}
                {format(selectedEndDate, 'dd/MM/yyyy')}
              </Typography>
            </Stack>
            <Divider classes={{ root: styles.lineDivider }} />
            <Stack
              mt={2}
              direction="row"
              justifyContent="space-around"
              alignItems="flex-end"
              spacing={6}
            >
              <MoneyBox
                text={'Entradas'}
                amount={cashIn}
                Icon={<ArrowCircleUpIcon color="success" />}
              />
              <MoneyBox
                text={'Saídas'}
                amount={cashOut}
                Icon={<ArrowCircleDownIcon color="error" />}
              />

              <MoneyBox text="Saldo previsto">
                <BalanceAmount
                  amount={projectedBalance.amount}
                  amountType={projectedBalance.type}
                  hasInconsistency={hasInconsistency}
                  someBalancePending={someBalancePending}
                  accounts={accounts}
                  defaultTipText="Saldo projetado das contas selecionadas, calculado com base nas transações lançadas na FinBits dentro do período filtrado."
                />
              </MoneyBox>
            </Stack>
          </Stack>
          <Stack ml={10}>
            <Stack direction="row" alignItems="center" mb={2}>
              <Typography variant="text-xs" fontWeight={600}>
                Realizado em {format(currentBalanceDate, 'dd/MM/yyyy')}
              </Typography>
            </Stack>
            <Divider classes={{ root: styles.lineDivider }} />
            <Stack mt={2} direction="row" alignItems="flex-end">
              <MoneyBox text="Saldo realizado">
                <BalanceAmount
                  amount={finalBalance.amount}
                  amountType={finalBalance.type}
                  hasInconsistency={hasInconsistency}
                  someBalancePending={someBalancePending}
                  accounts={accounts}
                  defaultTipText="Saldo realizado das contas selecionadas, calculado com base nas transações realizadas que foram lançadas na FinBits até o final do período filtrado."
                />
              </MoneyBox>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}
