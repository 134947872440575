import { useEffect } from 'react';

import { TabContext, TabPanel } from '@mui/lab';
import { useFormContext } from 'react-hook-form';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';

import { useTabPanelsContext } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';
import { BillTab } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';

import useBillModalFormWatcher from './useBillModalFormWatcher';
import BillPreviewProvider from './BillPreviewProvider';
import { ClassificationsTab, InformationTab, RecurrenceTab } from './TabPanels';
import styles from './BillContent.module.scss';

type Props = {
  bill?: BillPayable | BillPayableOptional;
};

export default function BillContent({ bill }: Props) {
  useBillModalFormWatcher();
  const { watch, resetField } = useFormContext();
  const { selectedBillTab } = useTabPanelsContext();
  const date = watch('date');

  useEffect(() => {
    const FIELDS_TO_RESET = [
      'endDate',
      'quantity',
      'frequencyInterval',
    ] as const;
    FIELDS_TO_RESET.forEach((fieldName: (typeof FIELDS_TO_RESET)[number]) => {
      resetField(fieldName);
    });
  }, [date, resetField]);

  return (
    <TabContext value={selectedBillTab}>
      <BillPreviewProvider>
        <InformationTab bill={bill} />
      </BillPreviewProvider>
      <TabPanel
        className={styles.tabPanelClassifications}
        value={BillTab.CLASSIFICATIONS}
      >
        <ClassificationsTab />
      </TabPanel>
      <TabPanel value={BillTab.RECURRENCE}>
        <RecurrenceTab />
      </TabPanel>
    </TabContext>
  );
}
