import type { BillPayable } from 'src/libs/finbits/Bills/types';

type Props = {
  bill: BillPayable;
  onSuccess?: () => void;
};

export default function useCopyPublicIdAction({ bill, onSuccess }: Props) {
  function handleCopyId() {
    navigator.clipboard.writeText(bill.publicId);
    onSuccess?.();
  }

  return { copyPublicId: handleCopyId };
}
