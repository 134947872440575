import { Button } from '@mui/material';

import { useOpenPortal } from 'src/ui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateBillDrawer from 'src/features/bills-to-pay/CreateBillDrawer';
import CreateBillModal from 'src/features/bills-to-pay/CreateBillModal';

export default function CreateBillButton() {
  const { isEnabled: isMigrateBillEnabled } = useExternalFeatureFlag(
    Feature.MIGRATE_SCHEDULE_BILL_DRAWER_TO_MODAL
  );

  const openPortal = useOpenPortal();

  function handleOpenCreateBillDrawer() {
    if (isMigrateBillEnabled) {
      openPortal(CreateBillModal);

      return;
    }

    openPortal(CreateBillDrawer, {
      hideExtraActions: true,
      overlay: true,
      originValues: { type: BalanceType.DEBIT },
    });
  }

  return (
    <WithAllowedPermission
      permissions={[
        {
          resource: 'bills',
          action: 'create',
        },
      ]}
      key="createScheduled"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenCreateBillDrawer}
      >
        Criar lançamento a pagar
      </Button>
    </WithAllowedPermission>
  );
}
