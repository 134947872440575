import type {
  BillPayableApprovalType,
  ClassificationLabels,
} from 'src/libs/finbits/Bills/types';
import type { Label } from 'src/libs/finbits/Labels/types';
import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { User } from 'src/libs/finbits/Organization/Users/types';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';

export enum EditableFields {
  accountId = 'accountId',
  amount = 'amount',
  attachmentsIds = 'attachmentsIds',
  categoryId = 'categoryId',
  date = 'date',
  description = 'description',
  contactId = 'contactId',
  relevantDate = 'relevantDate',
  type = 'type',
  scheduledEntryId = 'scheduleEntryId',
  comments = 'comments',
  paymentDetails = 'paymentDetails',
  isRecurrent = 'isRecurrent',
  quantity = 'quantity',
  frequency = 'frequency',
  labelsIds = 'labelsIds',
  classifications = 'classifications',
  assigneesIds = 'assigneesIds',
  approvalType = 'approvalType',
  notaFiscalId = 'notaFiscalId',
  notaFiscalNumber = 'notaFiscalNumber',
  notaFiscalIssueDate = 'notaFiscalIssueDate',
  dueDate = 'dueDate',
  companyId = 'companyId',
  inboxItemId = 'inboxItemId',
  financialEntryId = 'financialEntryId',
  paymentMethod = 'paymentMethod',
  externalId = 'externalId',
  accountDocument = 'paymentDetails.accountDocument',
  accountDocumentType = 'paymentDetails.accountDocumentType',
  accountNumber = 'paymentDetails.accountNumber',
  accountType = 'paymentDetails.accountType',
  bankName = 'paymentDetails.bankName',
  digitableLine = 'paymentDetails.digitableLine',
  branchNumber = 'paymentDetails.branchNumber',
  payeeName = 'paymentDetails.payeeName',
  pixKey = 'paymentDetails.pixKey',
  pixType = 'paymentDetails.pixType',
  routingNumber = 'paymentDetails.routingNumber',
}

export type SubmitParams = {
  accountId: string;
  amount: number;
  attachments: Attachment[];
  categoryId?: string;
  date: Date;
  description: string;
  id: string;
  contactId?: string | null;
  relevantDate?: Date;
  type: BalanceType;
  scheduledEntryId?: string | null;
  comments?: string | null;
  paymentDetails?: PaymentDetails | null;
  notaFiscalNumber?: string | null;
  notaFiscalIssueDate?: Date | null;
  dueDate?: Date | null;
  isRecurrent?: boolean;
  quantity?: number | null;
  frequencyInterval?: number | null;
  frequencyUnit?: string | null;
  classifications?: ClassificationLabels[];
  assigneesIds?: string[] | null;
  approvalType?: BillPayableApprovalType | null;
  notaFiscalId?: string | null;
};

export type FormValues = {
  accountId?: string | null;
  amount?: number | null;
  attachments?: Attachment[];
  categoryId?: string | null;
  date?: Date | null;
  description?: string;
  id?: string;
  originDescription?: string | null;
  contactId?: string | null;
  relevantDate?: Date | null;
  type?: BalanceType;
  scheduledEntryId?: string | null;
  comments?: string | null;
  paymentMethod?: string | null;
  paymentDetails?: PaymentDetails | null;
  isRecurrent?: boolean;
  quantity?: number | null;
  frequencyInterval?: number | null;
  frequencyUnit?: string | null;
  notaFiscalNumber?: string | null;
  notaFiscalIssueDate?: Date | null;
  dueDate?: Date | null;
  classifications?: ClassificationLabels[];
  assigneesIds?: string[] | null;
  approvalType?: BillPayableApprovalType | null;
  assignees?: User[];
  notaFiscalId?: string | null;
  labels?: Label[];
};

export type EntryFormHiddenFields = Array<
  keyof Pick<
    FormValues,
    | 'date'
    | 'accountId'
    | 'type'
    | 'paymentMethod'
    | 'paymentDetails'
    | 'frequencyInterval'
    | 'quantity'
    | 'isRecurrent'
    | 'originDescription'
    | 'classifications'
    | 'assigneesIds'
    | 'approvalType'
  >
>;

export type EntryFormHiddenActions = Array<
  'createReceivableWithNotaFiscal' | 'createNotaFiscalFromReceivable'
>;

export type EntryFormDisabledFields = Array<keyof SubmitParams>;
