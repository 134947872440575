import { TabList } from '@mui/lab';
import { AppBar, Tab } from '@mui/material';
import { AlertCircleIcon } from 'src/mui/_icons';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'src/mui';

import { useTabPanelsContext } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';
import { BillTab } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';
import SuggestionIcon from 'src/features/entries/Suggestions/SuggestionIcon/SuggestionIcon';

import styles from './BillTabs.module.scss';

const INFORMATION_TAB_FIELDS = [
  'accountId',
  'amount',
  'approvalType',
  'assigneesIds',
  'attachments',
  'comments',
  'contactId',
  'date',
  'description',
  'paymentDetails',
  'relevantDate',
];
const RECURRENCE_TAB_FIELDS = ['quantity', 'frequencyInterval'];

function a11yProps(index: number) {
  return {
    id: `bill-tabs-${index}`,
    'aria-controls': `bill-tabpanel-${index}`,
  };
}

export default function BillTabs() {
  const { setSelectedBillTab, disabledTabs = {} } = useTabPanelsContext();
  const {
    formState: { errors },
  } = useFormContext();

  const fieldsErrors = Object.keys(errors);
  const hasInformationTabErrors = fieldsErrors.some((field) =>
    INFORMATION_TAB_FIELDS.includes(field)
  );
  const hasRecurrenceTabErrors = fieldsErrors.some((field) =>
    RECURRENCE_TAB_FIELDS.includes(field)
  );

  return (
    <AppBar className={styles.appBar} position="sticky" color="transparent">
      <TabList
        textColor="secondary"
        indicatorColor="secondary"
        onChange={(_, value) => setSelectedBillTab(value)}
        className={styles.tabs}
      >
        <Tab
          className={styles.tab}
          label="Informações"
          value={BillTab.INFORMATION}
          disabled={disabledTabs[BillTab.INFORMATION]}
          iconPosition="end"
          icon={
            hasInformationTabErrors ? (
              <AlertCircleIcon
                aria-errormessage="A aba de informações contém erros"
                aria-invalid="true"
                className={styles.alertCircleIcon}
                data-testid="information-tab-alert"
              />
            ) : (
              <SuggestionIcon
                fieldName={INFORMATION_TAB_FIELDS}
                variant="tab"
              />
            )
          }
          {...a11yProps(0)}
        />
        <Tab
          className={styles.tab}
          label="Etiquetas"
          value={BillTab.CLASSIFICATIONS}
          disabled={disabledTabs[BillTab.CLASSIFICATIONS]}
          iconPosition="end"
          icon={<SuggestionIcon fieldName={/^classifications/} variant="tab" />}
          {...a11yProps(1)}
        />
        <Tab
          className={styles.tab}
          data-testid="recurrence-tab"
          label={
            <Tooltip
              disableHoverListener={!disabledTabs[BillTab.RECURRENCE]}
              title="Não é possível criar recorrência para um lançamento vinculado."
              style={{ pointerEvents: 'auto' }}
            >
              Recorrência
            </Tooltip>
          }
          value={BillTab.RECURRENCE}
          disabled={disabledTabs[BillTab.RECURRENCE]}
          iconPosition="end"
          icon={
            hasRecurrenceTabErrors ? (
              <AlertCircleIcon
                aria-errormessage="A aba de recorrência contém erros"
                aria-invalid="true"
                className={styles.alertCircleIcon}
                data-testid="recurrence-tab-alert"
              />
            ) : undefined
          }
          {...a11yProps(2)}
        />
      </TabList>
    </AppBar>
  );
}
