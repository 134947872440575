import type { BillPayable } from 'src/libs/finbits/Bills/types';

import TotalSum from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar/TotalSum';

import { ToolbarDatePicker } from './ToolbarDatePicker/ToolbarDatePicker';

type Props = {
  bills: BillPayable[];
  selectedDate: Date;
  setSelectedDate: (newDate: Date) => void;
};

export default function ToolbarQuickActions({
  bills,
  selectedDate,
  setSelectedDate,
}: Props) {
  return (
    <>
      <ToolbarDatePicker
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <TotalSum bills={bills} />
    </>
  );
}
