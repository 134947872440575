import type { ReactNode } from 'react';

import type { TextFieldProps } from '@mui/material';
import type { ControllerProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { InputAdornment } from '@mui/material';

import { TextField } from 'src/mui';

import { PixType } from 'src/libs/finbits/Bank/Pix/types';
import { cnpjMask, cpfMask } from 'src/libs/finbits/Documents';
import { cellphoneMask } from 'src/libs/finbits/Organization/PhoneNumber';

type Props = {
  loading?: boolean;
  pixType: PixType;
  TextFieldProps?: TextFieldProps;
  endAdornment?: ReactNode;
} & Omit<ControllerProps, 'render'>;

const PIX_INPUT = {
  [PixType.CPF]: {
    component: TextField.Masked,
    mask: [cpfMask],
    placeholder: 'Insira o CPF',
  },
  [PixType.CNPJ]: {
    component: TextField.Masked,
    mask: [cnpjMask],
    placeholder: 'Insira o CNPJ',
  },
  [PixType.CELLPHONE_NUMBER]: {
    component: TextField.Masked,
    mask: [cellphoneMask],
    placeholder: 'Insira o Celular',
  },
  [PixType.EMAIL]: {
    component: TextField.Masked,
    placeholder: 'Insira o E-mail',
  },
  [PixType.RANDOM]: {
    shouldUnMask: false,
    mask: ['SSSSSSSS-SSSS-SSSS-SSSS-SSSSSSSSSSSS'],
    component: TextField.Masked,
    placeholder: 'Insira a Chave Aleatória',
  },
};

export default function PixKeyField({
  pixType,
  defaultValue = '',
  TextFieldProps,
  endAdornment,
  ...rest
}: Props) {
  return (
    <Controller
      {...rest}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        const { component: TextField, ...props } = PIX_INPUT[pixType];

        return (
          <TextField
            {...field}
            {...props}
            {...TextFieldProps}
            helperText={error?.message}
            error={!!error}
            data-testid="pix-key-field"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
}
