import type { TextFieldProps } from '@mui/material';
import type { ControllerProps } from 'react-hook-form';
import { FormField, Select } from 'src/mui/_scss';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import AssigneeChip from 'src/features/assignee/AssigneeChip';
import type { AdornmentSuggestionOverlapFn } from 'src/features/entries/Suggestions/InputAdornmentSuggestion/endAdornmentFn';

type Props = {
  bill?: BillPayable;
  TextFieldProps?: TextFieldProps;
  endAdornmentFn?: AdornmentSuggestionOverlapFn;
} & Omit<ControllerProps, 'render'>;

export default function AssigneesField({
  bill,
  defaultValue = [],
  TextFieldProps,
  endAdornmentFn,
  ...rest
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { users, isLoading } = useCompanyUsers(
    {
      companyId,
      organizationId,
      onlyAvailableApprovers: true,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  return (
    <FormField defaultValue={defaultValue} label="Aprovadores" {...rest}>
      {(field) => {
        const assignees = users.filter((user) => field.value.includes(user.id));

        return (
          <Select
            endAdornmentFn={endAdornmentFn}
            disableClearable={field.disabled}
            disableCloseOnSelect
            multiple
            {...field}
            placeholder="Selecione os aprovadores"
            value={assignees}
            options={users}
            loading={isLoading}
            getOptionLabel={(option) => option.name || ''}
            onChange={(_e, value) => field.onChange(value.map((v) => v.id))}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={(assignees, getTagProps) =>
              assignees.map((assignee, index) => {
                const tagProps = getTagProps({ index });

                return (
                  <AssigneeChip
                    assignee={assignee}
                    billStatus={bill?.status}
                    assignments={bill?.lastApproval?.assignments}
                    label={assignee.name}
                    {...tagProps}
                  />
                );
              })
            }
          />
        );
      }}
    </FormField>
  );
}
