import { useCallback, useState } from 'react';

import {
  CircularProgress,
  ListItem,
  ListItemButton,
  Tooltip,
} from '@mui/material';
import { Stack } from '@mui/system';
import { FileIcon, TrashIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { FocusIcon, IconButton } from 'src/mui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { AttachmentOCRStatus } from 'src/libs/finbits/Management/Attachments/types';
import { useCompanyListener } from 'src/libs/finbits/Channels';

import styles from './UploadedAttachment.module.scss';

type Props = {
  attachment: Attachment;
  onDeleteAttachment: (attachment: Attachment) => void;
  onClickItem: (attachment: Attachment) => void;
};

export default function UploadedAttachment({
  attachment,
  onDeleteAttachment,
  onClickItem,
}: Props) {
  const [ocrStatus, setOcrStatus] = useState(attachment.ocrStatus);

  const ocrListener = useCallback(
    ({ attachmentId }: { attachmentId: string }) => {
      if (attachmentId === attachment.id) {
        setOcrStatus(AttachmentOCRStatus.COMPLETED);
      }
    },
    [attachment.id, setOcrStatus]
  );

  useCompanyListener('attachment_ocr_completed', ocrListener);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <Stack direction="row" className={styles.secondaryAction}>
          {ocrStatus === AttachmentOCRStatus.PENDING && (
            <Tooltip title="FinBot está processando o arquivo">
              <CircularProgress color="secondary" size={16} />
            </Tooltip>
          )}
          <IconButton
            onClick={() => onDeleteAttachment(attachment)}
            aria-label="Remover arquivo"
            title="Remover arquivo"
          >
            <TrashIcon />
          </IconButton>
        </Stack>
      }
    >
      <ListItemButton
        className={styles.item}
        onClick={() => onClickItem(attachment)}
        aria-label="Abrir anexo no preview"
      >
        <FocusIcon size="md">
          <FileIcon fontSize="small" viewBox="-7 6 30 10" />
        </FocusIcon>

        <Typography ellipsis fontWeight="500" className={styles.typography}>
          {attachment?.name}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
}
