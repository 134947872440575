import t from 'zod';

import type { OpenBankingConnectionFeaturesDecoder } from './decoder';

export enum Provider {
  BTG = 'btg',
  PLUGGY = 'pluggy',
}

const ConsenterDecoder = t.object({
  email: t.string(),
  document: t.string(),
});

export const WidgetSessionDecoder = t.object({
  token: t.string(),
  externalId: t.string().nullable(),
  clientId: t.string().nullable(),
  environment: t.string().nullable(),
  consenter: t.nullable(ConsenterDecoder),
});

export type WidgetSession = t.TypeOf<typeof WidgetSessionDecoder>;

export const OpenBankingConnectionStatusDecoder = t.union([
  t.literal('activating'),
  t.literal('active'),
  t.literal('invalid_credentials'),
  t.literal('mfa_required'),
  t.literal('expired'),
]);

export type OpenBankingConnectionStatus = t.TypeOf<
  typeof OpenBankingConnectionStatusDecoder
>;

export const OpenBankingConnectionDecoder = t.object({
  id: t.string(),
  routingNumber: t.string(),
  providerInstitutionCode: t.string(),
  status: OpenBankingConnectionStatusDecoder,
  bankName: t.string(),
  provider: t.string(),
  providerConnectionId: t.string(),
});

export const OpenBankingConnectionsDecoder = t.array(
  OpenBankingConnectionDecoder
);

export type OpenBankingConnection = t.TypeOf<
  typeof OpenBankingConnectionDecoder
>;

export const OpenBankingAuthenticationURLDecoder = t.object({
  url: t.string(),
});

export type OpenBankingAuthenticationURL = t.TypeOf<
  typeof OpenBankingAuthenticationURLDecoder
>;

export type WidgetSessionParams = {
  organizationId: string;
  companyId: string;
  provider: Provider;
  connectionId?: string;
  appName?: string;
  appIcon?: string;
  appLogo?: string;
  benefitHeader?: string;
  benefitContent?: string;
  opportunityLoss?: string;
  companyName?: string;
  companyDocument?: string;
  operatorName?: string;
  operatorDocument?: string;
};

export type GetParams = {
  companyId?: string;
  organizationId?: string;
};

export type GetOpenBankingFeaturesParams = GetParams & {
  accountIds: string[];
};

export type GetAuthURL = {
  companyId?: string;
  organizationId: string;
  provider: string;
};

type Institution = {
  name: string;
  code: string;
};

type Account = {
  externalId: string;
  branchNumber: string;
  accountNumber: string;
  accountType: string;
  bankAccountType: string;
};

export type CreateConnectionParams = {
  companyId: string;
  organizationId: string;
  providerInstitutionCode: string;
  provider: Provider;
  providerConnectionId?: string;
  authorizationCode?: string;
  institution?: Institution;
  accounts?: Account[];
};

export type ActivateConnectionParams = GetParams & {
  connectionId: string;
};

export type GetBTGAuthURL = {
  organizationId: string;
};

export type OpenBankingConnectionFeatures = t.TypeOf<
  typeof OpenBankingConnectionFeaturesDecoder
>;
