import type { ReactNode } from 'react';

import cn from 'classnames';
import type { TooltipProps } from '@mui/material';
import { Tooltip as MUITooltip } from '@mui/material';

import styles from './Tooltip.module.scss';

type Props = {
  children: ReactNode;
  classNames?: string;
} & Omit<TooltipProps, 'children'>;

export default function Tooltip({ children, classNames, ...rest }: Props) {
  return (
    <MUITooltip {...rest}>
      <span data-testid="span" className={cn([styles.span, classNames])}>
        {children}
      </span>
    </MUITooltip>
  );
}
