import type { InputHTMLAttributes, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

import type { Options as PopperOptions } from '@popperjs/core';
import cn from 'classnames';
import type {
  AutocompleteGetTagProps,
  AutocompleteValue,
  IconButtonProps,
  UseAutocompleteProps,
} from '@mui/material';
import { Divider, Fade, IconButton, useForkRef } from '@mui/material';
import { PopperUnstyled, useAutocomplete } from '@mui/base';
import Input from 'src/mui/_scss/Input';
import { ChevronDownIcon, CloseIcon } from 'src/mui/_icons';
import { Rotate } from 'src/mui/_transitions';

import type { AdornmentSuggestionOverlapFn } from 'src/features/entries/Suggestions/InputAdornmentSuggestion/endAdornmentFn';

import styles from './Select.module.scss';
import RenderTags from './RenderTags';

export type Props<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> = {
  endAdornmentFn?: AdornmentSuggestionOverlapFn;
  name?: string;
  error?: boolean;
  loading?: boolean;
  children?: ReactNode;
  placeholder?: string;
  'aria-label'?: string;
  renderOption?: (option: T) => ReactNode;
  renderTags?: (
    values: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    getTagProps: AutocompleteGetTagProps
  ) => ReactNode;
  bordered?: boolean;
  autoFocus?: boolean;
  ref?: Ref<HTMLDivElement>;
  limitTags?: number;
} & UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

const popperModifiers: PopperOptions['modifiers'] = [
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }: any) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    },
  },
];

function Select<
  T extends unknown,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false
>(
  {
    error,
    children,
    placeholder,
    disableClearable,
    getOptionLabel,
    renderOption,
    renderTags,
    multiple,
    bordered = true,
    autoFocus = false,
    disabled = false,
    limitTags = 0,
    endAdornmentFn,
    ...props
  }: Props<T, Multiple, DisableClearable, FreeSolo>,
  ref: Ref<HTMLDivElement>
) {
  const {
    anchorEl,
    popupOpen,
    groupedOptions,
    value,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    getTagProps,
    setAnchorEl,
    focused,
  } = useAutocomplete({
    ...props,
    multiple,
    disabled,
    disableClearable,
    getOptionLabel,
  });

  const {
    role,
    ref: inputRef,
    ...inputProps
  }: InputHTMLAttributes<HTMLInputElement> & {
    ref?: Ref<HTMLDivElement>;
  } = getInputProps();

  const hasValue = Array.isArray(value)
    ? Boolean(value.length)
    : Boolean(value);

  const inputForkRef = {
    ...inputProps,
    ref: useForkRef(ref, inputRef),
  };

  const showTags = hasValue && renderTags && multiple;

  function showIcon() {
    const Icon = !disableClearable ? (
      <IconButton
        className={cn(styles.clearIcon, {
          [styles.showIcon]: hasValue,
        })}
        size="small"
        aria-label="Limpar"
        title="Limpar"
        {...(getClearProps() as IconButtonProps)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ) : null;

    if (endAdornmentFn) {
      return endAdornmentFn({
        fieldName: props.name,
        focused,
        icon: Icon,
      });
    }

    return focused && hasValue ? Icon : null;
  }

  return (
    <div ref={setAnchorEl} className={styles.root} {...getRootProps()}>
      <Input
        autoFocus={autoFocus}
        bordered={bordered}
        error={error}
        disabled={disabled}
        className={showTags ? styles.inputWithTag : undefined}
        {...inputForkRef}
        slotProps={{
          input: {
            role,
            disabled,
            'aria-label': props['aria-label'],
          },
        }}
        placeholder={disabled ? undefined : placeholder}
        startAdornment={
          showTags && (
            <RenderTags
              renderTags={renderTags}
              value={value}
              limitTags={limitTags}
              getTagProps={getTagProps}
            />
          )
        }
        endAdornment={
          <>
            {showIcon()}
            <IconButton size="small" disabled={disabled}>
              <Rotate in={!disabled && popupOpen}>
                <ChevronDownIcon />
              </Rotate>
            </IconButton>
          </>
        }
      />

      <PopperUnstyled
        open={!disabled && popupOpen}
        anchorEl={anchorEl}
        className={styles.popper}
        transition
        modifiers={popperModifiers}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div className={styles.content}>
              <ul className={styles.listbox} {...getListboxProps()}>
                {groupedOptions.length === 0 && (
                  <li
                    className={styles.option}
                    aria-label="sem opções"
                    role="option"
                    aria-selected="true"
                  >
                    Sem opções
                  </li>
                )}

                {(groupedOptions as T[]).map((option, index) => {
                  const optionProps = getOptionProps({ option, index });
                  const label = getOptionLabel?.(option);

                  return (
                    <li
                      className={cn(styles.option, styles.selectable)}
                      aria-label={label}
                      {...optionProps}
                      key={index}
                    >
                      {renderOption ? renderOption(option) : label}
                    </li>
                  );
                })}
              </ul>
              {children && (
                <>
                  <Divider />
                  {children}
                </>
              )}
            </div>
          </Fade>
        )}
      </PopperUnstyled>
    </div>
  );
}

export default forwardRef(Select) as typeof Select;
