import { useNavigate } from 'react-router';
import { closeSnackbar } from 'notistack';
import { Button, Stack } from '@mui/material';
import { AlertCircleIcon } from 'src/mui/_icons';

import { snackbar, snackbarAction } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import {
  useCreateWaitingApproval,
  useUndoWaitingApproval,
} from 'src/libs/finbits/PaymentsFlow';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';

type Props = {
  selecteds: BillPayable[];
  onSuccess?: () => void;
};

const SNACKBAR_KEY = 'undo';

export default function useWaitingApprovalAction({
  selecteds,
  onSuccess,
}: Props) {
  const selectedIds = selecteds.map((bill) => bill.id);

  const navigate = useNavigate();

  const { companyId, organizationId } = useCompanyParams();
  const { createWaitingApproval, isLoading } = useCreateWaitingApproval();
  const { undoWaitingApproval } = useUndoWaitingApproval();
  const openConfirmDialog = useOpenConfirmDialog();

  function handleUndo() {
    closeSnackbar(SNACKBAR_KEY);

    undoWaitingApproval(
      {
        organizationId,
        companyId,
        billsIds: selectedIds,
      },
      {
        onSuccess: () => {
          snackbar({ variant: 'success', message: 'Ação desfeita' });
          onSuccess?.();
        },
        onError: () => {
          snackbar({ variant: 'error', message: 'Erro ao desfazer ação' });
        },
      }
    );
  }

  function sendToWaitingApproval() {
    if (
      selecteds.some(
        (bill) =>
          !canPerformAction(bill, AvailableActions.SEND_TO_WAITING_APPROVAL)
      )
    ) {
      openConfirmDialog({
        variant: 'warning',
        icon: <AlertCircleIcon />,
        title: 'Faltam algumas informações',
        content:
          'Preencha todas as informações dos lançamentos que deseja enviar para aprovação.',
        confirmText: 'Entendi',
        confirmButtonProps: {
          color: 'inherit',
          variant: 'outlined',
        },
      });

      return;
    }

    createWaitingApproval(
      {
        organizationId,
        companyId,
        billsIds: selectedIds,
      },
      {
        onSuccess: () => {
          onSuccess && onSuccess();

          snackbar({
            variant: 'success',
            key: SNACKBAR_KEY,
            title: `${pluralize(
              selectedIds.length,
              'Lançamento enviado',
              'Lançamentos enviados'
            )} para aprovação`,
            alignItems: 'start',
            customActions: (
              <Stack direction="row" spacing={2}>
                <Button
                  aria-label="Desfazer"
                  size="small"
                  color="secondary"
                  onClick={handleUndo}
                  sx={snackbarAction}
                >
                  Desfazer
                </Button>

                <Button
                  aria-label="Ver em aprovação"
                  size="small"
                  color="secondary"
                  sx={snackbarAction}
                  onClick={() =>
                    navigate({
                      search: `status=${BillStatus.WAITING_APPROVAL}`,
                    })
                  }
                >
                  Ver em aprovação
                </Button>
              </Stack>
            ),
          });
        },
        onError: (e) => {
          snackbar({ variant: 'error', message: e.response?.data.message });
        },
      }
    );
  }

  return {
    sendToWaitingApproval,
    isLoading,
    undosWaitingApproval: handleUndo,
  };
}
