import { useState } from 'react';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { useCreateBill, useCreateRecurringBill } from 'src/libs/finbits/Bills';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import { buildRecurrencePayload } from 'src/libs/finbits/Management/ScheduledRecurrences';
import { useInboxItemMerge } from 'src/libs/finbits/Management/NewInboxItems';

import BillModal from 'src/features/bills-to-pay/BillModal';

type Props = {
  inboxItemId?: string;
  onSuccess?: () => void;
} & PortalProps;

export default function CreateBillModal({
  inboxItemId,
  onClose,
  onSuccess,
  open = true,
  onExit,
}: Props) {
  const [apiErrors, setApiErrors] = useState<ApiErrorForm>();

  const { companyId, organizationId } = useCompanyParams();
  const { bill, isFetching } = useInboxItemMerge({
    companyId,
    organizationId,
    inboxItemId,
  });

  const { createBill, isLoading: isSaving } = useCreateBill();
  const { createRecurringBill, isLoading: isSavingRecurring } =
    useCreateRecurringBill();

  function handleCreateBill({
    attachments,
    classifications,
    ...params
  }: BillFormParams) {
    const routeParams = {
      companyId,
      organizationId,
    };

    const handleApiResponse = {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message: params.isRecurrenceEnabled
            ? 'Recorrência criada com sucesso'
            : 'O novo lançamento a pagar foi adicionado com sucesso!',
        });

        onSuccess?.();

        onClose();
      },
      onError: ({ response }: ApiError<ApiErrorForm>) => {
        snackbar({
          variant: 'error',
          message: params.isRecurrenceEnabled
            ? 'Ocorreu um erro ao criar a recorrência'
            : 'Ocorreu um erro ao criar o lançamento a pagar!',
        });

        if (response?.data.errors && isStructuredFormError(response?.data)) {
          setApiErrors(response.data.errors);
        }
      },
    };

    if (params.isRecurrenceEnabled) {
      const recurrencePayload = buildRecurrencePayload(
        {
          attachments,
          classifications,
          ...params,
        },
        !!inboxItemId ? [inboxItemId] : []
      );

      createRecurringBill(
        {
          ...recurrencePayload,
          ...routeParams,
        },
        handleApiResponse
      );

      return;
    }

    createBill(
      {
        ...params,
        inboxItemId,
        attachmentsIds: attachments?.map((attachment) => attachment.id),
        classifications: classifications ? Object.values(classifications) : [],
        ...routeParams,
      },
      handleApiResponse
    );
  }

  return (
    <BillModal
      inboxItemId={inboxItemId}
      onClose={onClose}
      open={open}
      onExit={onExit}
      onSubmit={handleCreateBill}
      isLoading={isFetching}
      isSaving={isSaving || isSavingRecurring}
      bill={bill}
      apiErrors={apiErrors}
    />
  );
}
