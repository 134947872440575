import { useLayoutEffect, useState } from 'react';

import { Button } from '@mui/material';

import { Tooltip } from 'src/mui';

import type { Action } from 'src/features/bills-to-pay/Actions/type';

type Props = {
  action?: Action;
  iconOnly?: boolean;
};

export default function ActionButton({ action, iconOnly = false }: Props) {
  const [renderClickedUI, setRenderClickedUI] = useState(false);

  useLayoutEffect(() => {
    const timer = setTimeout(() => setRenderClickedUI(false), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [renderClickedUI]);

  if (!action) return null;

  function handleClick() {
    action?.onClick();

    if (action?.uiAfterClick) {
      setRenderClickedUI(true);
    }
  }

  const label = renderClickedUI ? action.uiAfterClick?.label : action.label;
  const icon = renderClickedUI ? action.uiAfterClick?.icon : action.icon;

  return (
    <Tooltip title={action.label} disableHoverListener={!iconOnly}>
      <Button
        key={action.name}
        onClick={handleClick}
        startIcon={iconOnly ? undefined : icon}
        variant="outlined"
        aria-label={label}
      >
        {iconOnly ? icon : label}
      </Button>
    </Tooltip>
  );
}
