import isObject from 'lodash/isObject';

import type {
  PaymentSuggestion,
  SuggestionFields,
} from 'src/libs/finbits/Management/Entries/types';
import { ACCOUNT_TYPE_DICTIONARY } from 'src/libs/finbits/Bank/Accounts';
import type { AccountTypeLabel } from 'src/libs/finbits/Bank/Accounts';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';
import type { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { format as formatDate } from 'src/libs/finbits/Date';
import { formatCNPJ, formatCPF } from 'src/libs/finbits/Documents';
import { toCurrency } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';
import type { Bank } from 'src/libs/finbits/Bank/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { ClassificationLabels } from 'src/libs/finbits/Bills/types';

import { suggestionFieldNameDictionary } from './translation';
import { SuggestionField } from './type';

const suggestionsOrder = {
  [SuggestionField.DESCRIPTION]: 0,
  [SuggestionField.AMOUNT]: 1,
  [SuggestionField.DATE]: 2,
  [SuggestionField.ACCOUNT_ID]: 3,
  [SuggestionField.CONTACT_ID]: 4,
  [SuggestionField.CATEGORY_ID]: 5,
  [SuggestionField.PAYMENT_DETAILS_PAYMENT_METHOD]: 6,
  [SuggestionField.PAYMENTS_DETAILS]: 7,
  [SuggestionField.PAYMENT_DETAILS_DIGITABLE_LINE]: 8,
  [SuggestionField.PAYMENT_DETAILS_PIX_TYPE]: 9,
  [SuggestionField.PAYMENT_DETAILS_PIX_KEY]: 10,
  [SuggestionField.PAYMENT_DETAILS_ACCOUNT_DOCUMENT]: 11,
  [SuggestionField.PAYMENT_DETAILS_PAYEE_NAME]: 12,
  [SuggestionField.PAYMENT_DETAILS_ROUTING_NUMBER]: 13,
  [SuggestionField.PAYMENT_DETAILS_BANK_NAME]: 14,
  [SuggestionField.PAYMENT_DETAILS_ACCOUNT_TYPE]: 15,
  [SuggestionField.PAYMENT_DETAILS_ACCOUNT_NUMBER]: 16,
  [SuggestionField.PAYMENT_DETAILS_BRANCH_NUMBER]: 17,
  [SuggestionField.NOTA_FISCAL_NUMBER]: 18,
  [SuggestionField.NOTA_FISCAL_ISSUE_DATE]: 19,
  [SuggestionField.RELEVANT_DATE]: 20,
  [SuggestionField.DUE_DATE]: 21,
  [SuggestionField.COMMENTS]: 22,
};

const classificationsBaseIndex = 100;
const unknownBaseIndex = 200;

export type FieldItem = {
  fieldName: string;
  value: string | ClassificationLabels;
  label?: string;
  type?: 'international' | ContactType | null;
  fieldNameDisplay: string;
  index: number;
};

type FlatFields = {
  [key: string]: FieldItem;
};

type FieldOptions = keyof typeof suggestionFieldNameDictionary;

type BuildFieldsArguments = Omit<FieldItem, 'fieldNameDisplay' | 'index'> & {
  fieldNameDisplay?: string;
  index?: number;
};

function buildField({
  fieldName,
  value,
  label,
  type,
  fieldNameDisplay,
  index,
}: BuildFieldsArguments): FlatFields {
  return {
    [fieldName]: {
      value,
      label,
      fieldName,
      fieldNameDisplay:
        fieldNameDisplay ??
        suggestionFieldNameDictionary[fieldName as FieldOptions],
      type,
      index:
        index ??
        suggestionsOrder[fieldName as keyof typeof suggestionsOrder] ??
        unknownBaseIndex,
    },
  };
}

type SuggestionKeys = keyof SuggestionFields;
type PaymentSuggestionKeys = keyof PaymentSuggestion;

type Options = {
  banks?: Bank[];
  classifications?: Classification[];
};

function buildCategory(fields: SuggestionFields) {
  const categoryField = fields.category!;

  return buildField({
    fieldName: 'categoryId',
    value: categoryField.id!,
    label: categoryField.name,
  });
}

function buildAccount(fields: SuggestionFields) {
  const accountField = fields.account!;

  return buildField({
    fieldName: 'accountId',
    value: accountField.id!,
    label: accountField.name!,
  });
}

function buildContact(fields: SuggestionFields) {
  const contactField = fields.contact;

  return buildField({
    fieldName: 'contactId',
    value: contactField?.id! || contactField?.document!,
    label: contactField?.nickname!,
    type: contactField?.type,
  });
}

function buildPaymentDetails(fields: SuggestionFields, banks: Bank[]) {
  const paymentDetailsObject = fields.paymentDetails!;

  const paymentFields = Object.keys(
    paymentDetailsObject as PaymentSuggestion
  ).filter((field) =>
    Boolean(paymentDetailsObject[field as PaymentSuggestionKeys])
  );

  const paymentFieldsFlat = paymentFields.map((paymentFieldName) => {
    if (paymentFieldName === 'boleto') {
      const boletoField = paymentDetailsObject.boleto!;
      return buildField({
        fieldName: 'paymentDetails.digitableLine',
        value: boletoField.digitableLine,
      });
    }

    if (paymentFieldName === 'bankName') {
      return null;
    }

    if (paymentFieldName === 'routingNumber') {
      const bank = banks.find(
        ({ routingNumber }) =>
          paymentDetailsObject.routingNumber === routingNumber
      );

      return buildField({
        fieldName: 'paymentDetails.routingNumber',
        value: paymentDetailsObject.routingNumber!,
        label: `${paymentDetailsObject.routingNumber} - ${bank?.name}`,
      });
    }

    return buildField({
      fieldName: `paymentDetails.${paymentFieldName}`,
      value: paymentDetailsObject[
        paymentFieldName as PaymentSuggestionKeys
      ] as string,
    });
  });

  return Object.assign({}, ...paymentFieldsFlat);
}

function buildClassifications(
  fields: SuggestionFields,
  classifications: Classification[]
) {
  const classificationsFields = fields.classifications!.map(
    ({ id, labels }, index) => {
      const classification = classifications.find(
        (classification) => classification.id === id
      );
      return buildField({
        fieldName: `classifications.${id}`,
        fieldNameDisplay: classification?.name,
        value: { classificationId: id, labelsIds: labels.map(({ id }) => id) },
        label: labels.map(({ name }) => name).join(', '),
        index: classificationsBaseIndex + index,
      });
    }
  );
  return Object.assign({}, ...classificationsFields);
}

export function buildSuggestionItems(
  fields: SuggestionFields,
  options: Options = { banks: [] }
): FlatFields {
  if (!fields) return {};

  const fieldsValid = Object.keys(fields).filter((field) =>
    Boolean(fields[field as SuggestionKeys])
  );

  const flatFields = fieldsValid.map((fieldName) => {
    if (fieldName === 'account') {
      return buildAccount(fields);
    }

    if (fieldName === 'category') {
      return buildCategory(fields);
    }

    if (fieldName === 'contact') {
      return buildContact(fields);
    }

    if (fieldName === 'paymentDetails') {
      return buildPaymentDetails(fields, options.banks!);
    }

    if (fieldName === 'classifications') {
      return buildClassifications(fields, options.classifications!);
    }

    return buildField({
      fieldName,
      value: fields[fieldName as SuggestionKeys] as string,
    });
  });

  return Object.assign({}, ...flatFields);
}

export function formatValue(
  fieldName: string,
  value: string | ClassificationLabels
) {
  if (isObject(value)) {
    return value;
  }

  if (fieldName === 'paymentDetails.paymentMethod') {
    return PAYMENT_METHOD_DICTIONARY[value as PaymentMethod];
  }

  if (fieldName === 'paymentDetails.accountType') {
    return ACCOUNT_TYPE_DICTIONARY[value as AccountTypeLabel];
  }

  if (fieldName === 'date') {
    return formatDate(value, 'dd/MM/yyyy');
  }

  if (fieldName === 'notaFiscalIssueDate') {
    return formatDate(value, 'dd/MM/yyyy');
  }

  if (fieldName === 'dueDate') {
    return formatDate(value, 'dd/MM/yyyy');
  }

  if (fieldName === 'relevantDate') {
    return formatDate(value, 'MMM/yyyy');
  }

  if (fieldName === 'paymentDetails.accountDocument') {
    return value.length === 11 ? formatCPF(value) : formatCNPJ(value);
  }

  if (fieldName === 'amount') {
    return toCurrency(Number(value), BalanceType.CREDIT);
  }

  return value;
}
