import type {
  BillFormParams,
  ClassificationLabels,
  EntryClassification,
} from 'src/libs/finbits/Bills/types';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { parseISO } from 'src/libs/finbits/Date';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';

export function parseFormClassifications(
  classifications?: EntryClassification[] | null
) {
  if (!classifications) return;

  const classificationsObject: Record<string, ClassificationLabels> = {};
  classifications.forEach((classification) => {
    classificationsObject[classification.id] = {
      classificationId: classification.id,
      labelsIds: classification.labels.map((label) => label.id),
    };
  });

  return classificationsObject;
}

export function parseBillForm(
  bill?: BillPayableOptional
): Partial<BillFormParams> {
  const paymentDetails = bill?.paymentDetails ?? {
    paymentMethod: null,
  };

  return {
    accountId: bill?.accountId ?? undefined,
    amount: bill?.amount ?? 0,
    classifications: bill?.classifications
      ? parseFormClassifications(bill.classifications)
      : undefined,
    comments: bill?.comments ?? '',
    date: bill?.date ? parseISO(bill.date) : undefined,
    relevantDate: bill?.relevantDate ? parseISO(bill.relevantDate) : null,
    description: bill?.description ?? '',
    notaFiscalNumber: bill?.notaFiscalNumber ?? '',
    notaFiscalIssueDate: bill?.notaFiscalIssueDate
      ? parseISO(bill.notaFiscalIssueDate)
      : undefined,
    dueDate: bill?.dueDate ? parseISO(bill.dueDate) : undefined,
    paymentDetails: {
      ...paymentDetails,
      digitableLine: paymentDetails.boleto?.digitableLine || null,
    },
    approvalType: bill?.approvalType ?? BillPayableApprovalType.ALL,
    assigneesIds: bill?.assignees?.map((assign) => assign.id) || [],
    attachments: bill?.attachments || [],
    categoryId: bill?.categoryId ?? null,
    contactId: bill?.contactId ?? null,
    isRecurrenceEnabled: false,
  };
}
