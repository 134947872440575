import { Chip } from 'src/design-system/components';
import { AlertTriangleIcon, ClockIcon, InfoCircleIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import type { SummaryAccount } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import BalanceInconsistencyDialog from 'src/features/balance-inconsistency/BalanceInconsistencyDialog';
import AmountBox from 'src/features/entries/EntriesSummary/MoneyBox/AmountBox';

import styles from './BalanceAmount.module.scss';

type Props = {
  accounts: SummaryAccount[];
  amount: number;
  amountType: BalanceType;
  defaultTipText?: string;
  hasInconsistency: boolean;
  someBalancePending: boolean;
  textColor?: string;
  showIconInfo?: boolean;
};

function getBalanceColor(balanceType: string) {
  return balanceType === BalanceType.DEBIT ? 'error.500' : undefined;
}

export default function BalanceAmount({
  accounts,
  amount,
  amountType,
  defaultTipText,
  hasInconsistency,
  someBalancePending,
  textColor = 'text.primary',
  showIconInfo = true,
}: Props) {
  const openPortal = useOpenPortal();

  function handleOpenBalanceInconsistencyDrawer() {
    if (Boolean(accounts.length)) {
      openPortal(BalanceInconsistencyDialog, { accounts: accounts });
    }
  }

  if (!hasInconsistency && someBalancePending && accounts.length === 1) {
    return (
      <AmountBox
        alternativeText="Em atualização"
        Icon={
          <InfoCircleIcon color="disabled" className={styles.balanceIcon} />
        }
        tipText="Após a inclusão de uma nova conta bancária e seu saldo inicial, os saldos são recalculados e exibidos em até 1 dia útil."
        textColor={textColor}
      />
    );
  }

  if (!hasInconsistency && someBalancePending) {
    return (
      <AmountBox
        amount={amount}
        Icon={<ClockIcon color="warning" fontSize="xsmall" />}
        textColor={getBalanceColor(amountType)}
        amountType={amountType}
        tipText="Este valor pode apresentar divergências devido a pendência de inclusão do saldo inicial de uma ou mais contas bancárias adicionadas."
      />
    );
  }

  if (hasInconsistency) {
    return (
      <AmountBox
        amount={amount}
        Icon={
          <Chip
            square
            variant="filled"
            color="warning"
            size="small"
            icon={<AlertTriangleIcon color="warning" fontSize="xsmall" />}
          />
        }
        onClick={handleOpenBalanceInconsistencyDrawer}
        textColor="warning.600"
        amountType={amountType}
        tipText="Clique para verificar divergências encontradas entre o saldo exibido na FinBits e o saldo bancário."
      />
    );
  }

  return (
    <AmountBox
      amount={amount}
      Icon={
        showIconInfo ? (
          <InfoCircleIcon color="disabled" className={styles.balanceIcon} />
        ) : undefined
      }
      textColor={getBalanceColor(amountType) || textColor}
      amountType={amountType}
      tipText={defaultTipText}
    />
  );
}
