import { Box, Button, DialogContent, Slide, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import type { TransitionProps } from '@mui/material/transitions';
import { AlertCircleIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { Dialog } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { useOpenConfirmDialog } from 'src/ui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { ReprovedBillParams } from 'src/libs/finbits/PaymentsFlow/types';

import useReprovedAction from 'src/features/bills-to-pay/Actions/reprove-action/hooks/useReprovedAction';

import ReproveFields from '../ReproveFields';

import styles from './ReproveBillModal.module.scss';

type Props = PortalProps & {
  selecteds: BillPayable[];
  onSuccess?: () => void;
};

export default function ReproveBillModal({
  open = true,
  onClose,
  selecteds,
  onSuccess,
  onExit,
}: Props) {
  const form = useForm<ReprovedBillParams>({
    defaultValues: {
      reason: '',
      shouldDelete: false,
    },
  });

  const { handleReproveBill } = useReprovedAction({
    selecteds: selecteds,
    onSuccess: handleSuccess,
  });
  const openConfirmDialog = useOpenConfirmDialog();

  function handleSuccess() {
    onSuccess?.();
    onClose();
  }

  function handleOnSubmit(params: ReprovedBillParams) {
    handleReproveBill(params);
  }

  function openCloseConfirmationModal() {
    openConfirmDialog({
      variant: 'error',
      title: 'Confirmar cancelamento da reprovação?',
      icon: <AlertCircleIcon />,
      content: (
        <Typography marginTop={4} marginBottom={4}>
          Ao cancelar, os itens selecionados não serão reprovados.
        </Typography>
      ),
      confirmText: 'Confirmar',
      cancelText: 'Voltar',
      onConfirm: onClose,
    });
  }

  return (
    <Dialog
      className={styles.modal}
      title="Reprovar"
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={openCloseConfirmationModal}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
    >
      <Dialog.Header
        title="Confirmar reprovação"
        onClose={openCloseConfirmationModal}
      />
      <FormProvider {...form}>
        <Box component="form" onSubmit={form.handleSubmit(handleOnSubmit)}>
          <DialogContent className={styles.dialogContent}>
            <Stack gap={4} className={styles.subtitle}>
              <Typography>
                Para confirmar a reprovação dos lançamentos, por favor, escreva
                um motivo para solicitar ajustes.
              </Typography>
              <Typography>
                O pagamento continuará sendo exibido em suas ações e fluxo de
                caixa.
              </Typography>
            </Stack>
            <ReproveFields />
          </DialogContent>
          <Dialog.Actions>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={openCloseConfirmationModal}
            >
              Cancelar
            </Button>
            <Button
              fullWidth
              size="large"
              color="error"
              variant="contained"
              type="submit"
            >
              {form.watch('shouldDelete') ? 'Reprovar e excluir' : 'Reprovar'}
            </Button>
          </Dialog.Actions>
        </Box>
      </FormProvider>
    </Dialog>
  );
}
