import { Box, Collapse, Stack } from '@mui/material';
import { TabPanel } from '@mui/lab';
import cn from 'classnames';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';

import BillTabs from 'src/features/bills-to-pay/BillTabs';
import BillPreview from 'src/features/bills-to-pay/BillContent/BillPreview';
import {
  EXPANSION_ENTER_TIME,
  EXPANSION_EXIT_TIME,
  useBillPreviewContext,
} from 'src/features/bills-to-pay/BillContent/BillPreviewProvider';
import { BillTab } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';

import BillAlerts from './BillAlerts';
import InformationFields from './InformationFields';
import styles from './InformationTab.module.scss';

type Props = {
  bill?: BillPayable | BillPayableOptional;
};

export default function InformationTab({ bill }: Props) {
  const { isExpandedPreview } = useBillPreviewContext();

  return (
    <Stack direction="row" className={styles.container}>
      <Collapse
        in={!isExpandedPreview}
        orientation="horizontal"
        data-testid="information-tab"
        timeout={{
          exit: EXPANSION_EXIT_TIME,
          enter: EXPANSION_ENTER_TIME,
        }}
        classes={{
          root: styles.collapseRoot,
          wrapper: styles.collapseWrapper,
          wrapperInner: styles.collapseWrapperInner,
        }}
      >
        <BillAlerts bill={bill} />
        <BillTabs />
        <TabPanel
          value={BillTab.INFORMATION}
          aria-label="Informações"
          className={styles.information}
        >
          <InformationFields />
        </TabPanel>
      </Collapse>
      <Box className={styles.preview}>
        <TabPanel
          value={BillTab.INFORMATION}
          className={cn(styles.previewPanel, {
            [styles.expanded]: isExpandedPreview,
          })}
        >
          <BillPreview />
        </TabPanel>
      </Box>
    </Stack>
  );
}
