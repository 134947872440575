import type { ReactNode } from 'react';
import { useEffect } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import { Button, Chip, DialogContent, Slide, Stack } from '@mui/material';
import { ArrowNarrowDownIcon } from 'src/mui/_icons';
import { LoadingButton } from '@mui/lab';
import { FormProvider } from 'react-hook-form';

import { Dialog } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Loader } from 'src/ui';

import type { ApiErrorForm } from 'src/libs/finbits/client';
import type { BillFormParams, BillPayable } from 'src/libs/finbits/Bills/types';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';
import { setErrorsInForm } from 'src/libs/finbits/Form';

import TabPanelsProvider from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import BillContent from 'src/features/bills-to-pay/BillContent';
import useBillForm from 'src/features/bills-to-pay/useBillForm';
import EntrySuggestionsProvider from 'src/features/entries/Suggestions/EntrySuggestionsProvider';
import type { BillTabValues } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';
import EditableFieldsProvider from 'src/features/bills-to-pay/BillContent/EditableFieldsProvider';
import type { DirtyFields } from 'src/features/entries/Suggestions/EntrySuggestionsProvider/types';

import styles from './BillModal.module.scss';

type Props = {
  bill?: BillPayable | BillPayableOptional;
  isLoading?: boolean;
  isSaving?: boolean;
  onSubmit: (params: BillFormParams) => void;
  apiErrors?: ApiErrorForm;
  disabledTabs?: BillTabValues;
  inboxItemId?: string;
  isEditMode?: boolean;
  Actions?: ReactNode;
  HeaderActions?: ReactNode;
} & PortalProps;

export default function BillModal({
  onClose,
  open = true,
  onExit,
  bill,
  isLoading = true,
  isSaving = false,
  onSubmit,
  apiErrors,
  disabledTabs,
  inboxItemId,
  isEditMode,
  Actions,
  HeaderActions,
}: Props) {
  const openLeaveDialog = useOpenConfirmLeaveFormDialog();

  const form = useBillForm({
    bill,
    isBillLoading: isLoading,
  });
  const {
    formState: { isDirty, dirtyFields },
    watch,
    setError,
  } = form;

  function handleClose() {
    if (isDirty) {
      return openLeaveDialog({
        onConfirm: onClose,
      });
    }

    onClose();
  }

  async function handleClick() {
    form.handleSubmit(onSubmit)();
  }

  useEffect(() => {
    if (apiErrors) {
      setErrorsInForm(apiErrors, setError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrors]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={handleClose}
      fullWidth
      maxWidth="uxl"
      // TODO: Habilitar essa prop novamente quando tivermos a drawer de contato usando componentes do MUI
      disableEnforceFocus
    >
      <Dialog.Header
        size="small"
        flex={1}
        title={
          <Stack
            direction="row"
            gap={2}
            flex={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              Pagamento Programado
              <Chip
                label="Saída"
                color="error"
                size="small"
                icon={<ArrowNarrowDownIcon strokeWidth="2.5" />}
              />
            </div>
            <div>{HeaderActions}</div>
          </Stack>
        }
        onClose={handleClose}
      />

      <DialogContent className={styles.dialogContent} dividers>
        {isLoading ? (
          <Loader />
        ) : (
          <FormProvider {...form}>
            <EditableFieldsProvider
              isEditMode={isEditMode}
              editableFields={bill?.editableFields}
            >
              <TabPanelsProvider disabledTabs={disabledTabs}>
                <EntrySuggestionsProvider
                  attachments={watch('attachments')}
                  contactId={watch('contactId')}
                  paymentMethod={watch('paymentDetails.paymentMethod')}
                  inboxItemId={inboxItemId}
                  contact={bill?.contact}
                  suggestedFields={bill?.suggestedFields}
                  dirtyFields={dirtyFields as DirtyFields}
                >
                  <BillContent bill={bill} />
                </EntrySuggestionsProvider>
              </TabPanelsProvider>
            </EditableFieldsProvider>
          </FormProvider>
        )}
      </DialogContent>

      <Dialog.Actions className={styles.dialogActions}>
        {Actions}
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isSaving}
          onClick={handleClick}
          variant="contained"
          color="primary"
        >
          {form.watch('isRecurrenceEnabled')
            ? 'Salvar e criar recorrência'
            : 'Salvar'}
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
