import { useMemo } from 'react';

import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { Typography } from 'src/design-system/components';
import create from 'zustand';

import { useGetBills } from 'src/libs/finbits/Bills';
import { endOfMonth, format } from 'src/libs/finbits/Date';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import BillPageTabs from 'src/features/bills-to-pay/BillPageTabs';
import useBillStatusFromUrl from 'src/features/bills-to-pay/BillPageTabs/hooks/useBillStatusFromUrl';
import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';
import { STORAGE_KEY } from 'src/features/bills-to-pay/BillsToPayDataGrid/BillsToPayDataGrid';
import {
  TableRootActions,
  ToolbarSelectedActions,
} from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar';
import ToolbarQuickActions from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar/ToolbarQuickActions/ToolbarQuickActions';

import ActionsButton from './ActionsButton';
import styles from './BillsToPay.module.scss';
import CreateBillButton from './CreateBillButton';

type StoreState = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

const useStore = create<StoreState>((set) => ({
  selectedDate: endOfMonth(new Date()),
  setSelectedDate: (newDate: Date) => set(() => ({ selectedDate: newDate })),
}));

export default function BillsToPay() {
  const { selectedDate, setSelectedDate } = useStore();
  const { isEnabled: isFilterEnabled } = useExternalFeatureFlag(
    Feature.BILL_PAGE_DATE_FILTER
  );
  const { isEnabled: isNewHeaderEnabled } = useExternalFeatureFlag(
    Feature.NEW_HEADER_ACCOUNTS_PAYABLE
  );

  const apiRef = useGridApiRef();
  const { status: selectedTab } = useBillStatusFromUrl();
  const { organizationId, companyId } = useCompanyParams();

  const { bills, isLoading, isFetching } = useGetBills({
    companyId,
    organizationId,
    status: selectedTab,
    endDate: isFilterEnabled ? format(selectedDate, 'yyyy-MM-dd') : undefined,
  });

  const GridToolBarSlots = useMemo(() => {
    if (!isFilterEnabled) return undefined;

    return {
      SelectedActions: ToolbarSelectedActions,
      TableRootActions: isNewHeaderEnabled ? undefined : TableRootActions,
      QuickActions: () => (
        <ToolbarQuickActions
          bills={bills}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      ),
      selectedText: ['lançamento selecionado', 'lançamentos selecionados'] as [
        string,
        string
      ],
    };
  }, [
    bills,
    selectedDate,
    setSelectedDate,
    isFilterEnabled,
    isNewHeaderEnabled,
  ]);

  return (
    <Box className={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        gap={6}
      >
        <Typography variant="text-lg" fontWeight="bold" component="h6">
          Contas a pagar
        </Typography>

        {isNewHeaderEnabled && (
          <Stack direction="row" gap={2}>
            <CreateBillButton />

            <ActionsButton apiRef={apiRef} />
          </Stack>
        )}
      </Stack>

      <BillPageTabs />

      <BillsToPayDataGrid
        apiRef={apiRef}
        bills={bills}
        isLoading={isLoading || isFetching}
        billStatus={selectedTab}
        storageKey={`${STORAGE_KEY}_${selectedTab}_${companyId}`}
        GridToolBarSlots={GridToolBarSlots}
      />
    </Box>
  );
}
