import { Divider } from '@mui/material';
import { ChevronDownIcon } from 'src/mui/_icons';

import { Dropdown } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/CreateFinancialEntryDrawer';
import CreateScheduledDrawer from 'src/features/scheduled/CreateScheduledDrawer';

import styles from './CreateEntriesDropdown.module.scss';

export default function CreateEntriesDropdown() {
  const openPortal = useOpenPortal();
  function handleOpenCreateScheduledDrawer() {
    openPortal(CreateScheduledDrawer);
  }

  function handleOpenCreateFinancialEntryDrawer() {
    openPortal(CreateFinancialEntryDrawer);
  }

  return (
    <Dropdown
      aria-label="Criar lançamento"
      color="primary"
      variant="contained"
      endIcon={() => <ChevronDownIcon />}
      PopoverProps={{
        className: styles.popover,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      options={[
        <WithAllowedPermission
          permissions={[
            {
              resource: 'bills',
              action: 'create',
            },
            {
              resource: 'receivables',
              action: 'create',
            },
          ]}
          key="createScheduled"
        >
          <Dropdown.Option onClick={handleOpenCreateScheduledDrawer}>
            Programado
          </Dropdown.Option>
        </WithAllowedPermission>,

        <Divider key="uploadsDivider" />,

        <WithAllowedPermission
          permissions={{
            resource: 'financialEntries',
            action: 'create',
          }}
          key="createSettled"
        >
          <Dropdown.Option onClick={handleOpenCreateFinancialEntryDrawer}>
            Realizado
          </Dropdown.Option>
        </WithAllowedPermission>,
      ]}
    >
      Criar lançamento
    </Dropdown>
  );
}
