import { EmptyState as EmptyStateOverlay } from 'src/mui/_scss/Table';

export default function EmptyState() {
  return (
    <EmptyStateOverlay
      title="Sem pagamentos para exibir"
      subTitle={
        <>
          A busca ou filtro aplicado não retornou <br />
          nenhum lançamento.
        </>
      }
    />
  );
}
