import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { BillFormResolver } from 'src/libs/finbits/Bills/validations';
import type { BillPayableOptional } from 'src/libs/finbits/Management/NewInboxItems/types';

import { parseBillForm } from './formValues';

type Arguments = {
  bill?: BillPayableOptional;
  isBillLoading?: boolean;
};

export default function useBillForm({
  bill,
  isBillLoading = false,
}: Arguments): UseFormReturn<BillFormParams> {
  const form = useForm<BillFormParams>({
    resolver: zodResolver(BillFormResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: parseBillForm(bill),
  });

  useEffect(() => {
    if (bill) {
      form.reset(parseBillForm(bill));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBillLoading]);

  return form;
}
