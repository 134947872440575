import compact from 'lodash/compact';
import {
  CheckIcon,
  Hash02Icon,
  RefreshIcon,
  SendToApprovalIcon,
  SendToPaymentIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  UndoApprovedIcon,
  UndoSendToApprovalIcon,
  UndoWaitingPaymentIcon,
} from 'src/mui/_icons';

import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import {
  AvailableActions,
  EntrySituation,
} from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { useRowActions as useEntryRowAction } from 'src/features/entries/EntriesDataGrid/ContextMenuRowActions/useRowActions';
import useWaitingPaymentAction from 'src/features/bills-to-pay/Actions/send-to-payment-action/hooks/useWaitingPaymentAction';
import useWaitingApprovalAction from 'src/features/bills-to-pay/Actions/send-to-approval-action/hooks/useWaitingApprovalAction';
import useApprovedAction from 'src/features/bills-to-pay/Actions/approve-action/hooks/useApprovedAction';
import useReprovedAction from 'src/features/bills-to-pay/Actions/reprove-action/hooks/useReprovedAction';
import useMarkAsFinancialAction from 'src/features/bills-to-pay/Actions/mark-as-financial-action/hooks/useMarkAsFinancialAction';
import useRestoreAction from 'src/features/bills-to-pay/Actions/restore-action/hooks/useRestoreAction';
import useCopyPublicIdAction from 'src/features/bills-to-pay/Actions/copy-public-id/hooks/useCopyPublicIdAction';

import { useAction } from './useAction';
import type { Action } from './type';

function parseRowToFinancialStatementEntry(
  bill: BillPayable
): FinancialStatementEntry {
  return {
    ...bill,
    isSplit: false,
    transactionId: null,
    notaFiscalId: null,
    notaFiscalNumber: null,
    notaFiscalIssueDate: null,
    dueDate: null,
    classifications: bill.classifications ?? [],
    currentSituation: bill.currentSituation ?? EntrySituation.PENDING,
    editableFields: bill.editableFields ?? [],
    availableActions: bill.availableActions ?? [],
  };
}

export function useBuildActions(bill: BillPayable, onSuccess?: () => void) {
  const { actionsForRow: defaultActionForRow } = useEntryRowAction(
    parseRowToFinancialStatementEntry(bill)
  );

  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const { sendToWaitingApproval, undosWaitingApproval } =
    useWaitingApprovalAction({
      selecteds: [bill],
      onSuccess,
    });

  const { sendToWaitingPayment, undosWaitingPayment } = useWaitingPaymentAction(
    {
      selecteds: [bill],
      onSuccess,
    }
  );

  const { sendToApproved, undosApproved } = useApprovedAction({
    selecteds: [bill],
    onSuccess,
  });

  const { sendToReproved } = useReprovedAction({
    selecteds: [bill],
    onSuccess,
  });

  const { markAsFinancial } = useMarkAsFinancialAction({
    bill: bill,
    onSuccess,
  });

  const { restore } = useRestoreAction({ selecteds: [bill], onSuccess });

  const sendToWaitingPaymentItem = useAction({
    action: {
      'aria-label': 'Enviar para Pagamento',
      name: 'sendForPayment',
      label: 'Enviar para Pagamento',
      onClick: sendToWaitingPayment,
      icon: <SendToPaymentIcon />,
    },
    permissions: {
      action: 'create',
      resource: 'waitingPayments',
      scope: 'company',
    },
    enabled:
      canPerformAction(bill, AvailableActions.SEND_TO_MANUAL_PAYMENT) ||
      canPerformAction(bill, AvailableActions.SEND_TO_AUTOMATIC_PAYMENT),
  });

  const undoWaitingPaymentItemLabel = isEnabledBillsApprovals
    ? 'Retornar para Aprovados'
    : 'Reenviar para A pagar';
  const undoWaitingPaymentItem = useAction({
    action: {
      'aria-label': undoWaitingPaymentItemLabel,
      name: 'undoSendToWaitingPayment',
      label: undoWaitingPaymentItemLabel,
      icon: <UndoWaitingPaymentIcon />,
      onClick: undosWaitingPayment,
    },
    permissions: {
      action: 'undo',
      resource: 'waitingPayments',
      scope: 'company',
    },
    enabled: canPerformAction(
      bill,
      AvailableActions.UNDO_SEND_TO_WAITING_PAYMENT
    ),
  });

  const isReproved = bill.status === BillStatus.REPROVED;
  const sendToWaitingApprovalItemLabel = isReproved
    ? 'Reenviar para aprovação'
    : 'Enviar para aprovação';
  const sendToWaitingApprovalItem = useAction({
    action: {
      'aria-label': sendToWaitingApprovalItemLabel,
      name: 'sendForApproval',
      label: sendToWaitingApprovalItemLabel,
      onClick: sendToWaitingApproval,
      icon: isReproved ? <UndoApprovedIcon /> : <SendToApprovalIcon />,
    },
    permissions: {
      action: 'create',
      resource: 'waitingApprovalPayments',
      scope: 'company',
    },
    enabled: canPerformAction(bill, AvailableActions.SEND_TO_WAITING_APPROVAL),
  });

  const undoWaitingApprovalItem = useAction({
    action: {
      'aria-label': 'Retornar para ”A pagar”',
      name: 'undoSendToWaitingApproval',
      label: 'Retornar para ”A pagar”',
      icon: <UndoSendToApprovalIcon />,
      onClick: undosWaitingApproval,
    },
    permissions: {
      action: 'undo',
      resource: 'waitingApprovalPayments',
      scope: 'company',
    },
    enabled: canPerformAction(
      bill,
      AvailableActions.UNDO_SEND_TO_WAITING_APPROVAL
    ),
  });

  const sendToApprovedItem = useAction({
    action: {
      'aria-label': 'Aprovar',
      name: 'sendToApproved',
      label: 'Aprovar',
      icon: <ThumbsUpIcon />,
      onClick: () => sendToApproved(),
    },
    permissions: {
      action: 'create',
      resource: 'approvedPayments',
      scope: 'company',
    },
    enabled: canPerformAction(bill, AvailableActions.SEND_TO_APPROVED),
  });

  const undoApprovedItem = useAction({
    action: {
      'aria-label': 'Reenviar para aprovação',
      name: 'undoSendToApproved',
      label: 'Reenviar para aprovação',
      icon: <UndoApprovedIcon />,
      onClick: () => undosApproved(),
    },
    permissions: {
      action: 'undo',
      resource: 'approvedPayments',
      scope: 'company',
    },
    enabled: canPerformAction(bill, AvailableActions.UNDO_SEND_TO_APPROVED),
  });

  const sendToReprovedItem = useAction({
    action: {
      'aria-label': 'Reprovar',
      name: 'sendToReproved',
      label: 'Reprovar',
      icon: <ThumbsDownIcon />,
      onClick: sendToReproved,
    },
    permissions: {
      action: 'create',
      resource: 'reprovedPayments',
      scope: 'company',
    },
    enabled: canPerformAction(bill, AvailableActions.SEND_TO_REPROVED),
  });

  const markAsFinancialItem = useAction({
    action: {
      'aria-label': 'Marcar como realizado',
      name: 'markAsFinancial',
      label: 'Marcar como realizado',
      icon: <CheckIcon />,
      onClick: markAsFinancial,
    },
    permissions: {
      action: 'create_from_bill',
      resource: 'financialEntries',
      scope: 'company',
    },
    enabled: canPerformAction(bill, AvailableActions.MARK_AS_FINANCIAL_ENTRY),
  });

  const restoreItem = useAction({
    action: {
      name: 'restoreEntry',
      icon: <RefreshIcon />,
      label: 'Restaurar',
      onClick: restore,
    },
    permissions: {
      action: 'restore_many',
      resource: 'entries',
    },
    enabled: canPerformAction(bill, AvailableActions.RESTORE),
  });

  const { copyPublicId } = useCopyPublicIdAction({
    bill,
  });

  const copyPublicIdItem = useAction({
    action: {
      name: 'copyPublicIdEntry',
      icon: <Hash02Icon />,
      label: 'Copiar ID',
      onClick: copyPublicId,
      uiAfterClick: {
        label: 'Copiado',
        icon: <CheckIcon />,
      },
    },
  });

  function buildAllActions(): Action[] {
    return compact([
      ...defaultActionForRow(),
      restoreItem,
      sendToApprovedItem,
      sendToReprovedItem,
      sendToWaitingApprovalItem,
      sendToWaitingPaymentItem,
      undoWaitingApprovalItem,
      undoApprovedItem,
      undoWaitingPaymentItem,
      markAsFinancialItem,
    ]);
  }

  return {
    buildAllActions,
    restoreAction: restoreItem,
    sendToApprovedAction: sendToApprovedItem,
    sendToReprovedAction: sendToReprovedItem,
    sendToWaitingApprovalAction: sendToWaitingApprovalItem,
    sendToWaitingPaymentAction: sendToWaitingPaymentItem,
    undoWaitingApprovalAction: undoWaitingApprovalItem,
    undoApprovedAction: undoApprovedItem,
    undoWaitingPaymentAction: undoWaitingPaymentItem,
    markAsFinancialAction: markAsFinancialItem,
    copyPublicIdAction: copyPublicIdItem,
  };
}
