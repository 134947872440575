import InputAdornmentSuggestion from './InputAdornmentSuggestion';

export type AdornmentSuggestionOverlapArgs = {
  focused: boolean;
  icon: JSX.Element | null;
  fieldName?: string;
};

export type AdornmentSuggestionOverlapFn = (
  opts: AdornmentSuggestionOverlapArgs
) => JSX.Element;

export function AdornmentSuggestionOverlap({
  focused,
  icon,
  fieldName,
}: AdornmentSuggestionOverlapArgs) {
  return (
    <InputAdornmentSuggestion
      type="overlap"
      fieldName={fieldName}
      showCustomIcon={focused}
    >
      {icon}
    </InputAdornmentSuggestion>
  );
}
