import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Permissions } from 'src/libs/finbits/Permissions/types';

import type { Action } from './type';

export function useAction({
  action,
  permissions,
  enabled = true,
}: {
  action: Action;
  permissions?: Permissions | Permissions[];
  enabled?: boolean;
}) {
  const havePermission = useAllowedPermission(permissions);

  if (!enabled || (!havePermission && Boolean(permissions))) return undefined;

  return action;
}
