import type { GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { useBuildActions } from 'src/features/bills-to-pay/Actions/useBuildActions';

export function RowActions({ row }: Pick<GridRowParams<BillPayable>, 'row'>) {
  const { buildAllActions } = useBuildActions(row);

  const actions = buildAllActions();

  return actions.map(({ dividerAfter, ...action }) => (
    <GridActionsCellItem {...action} key={action.name} showInMenu />
  ));
}
