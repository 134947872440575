import { Skeleton, Stack } from '@mui/material';

import styles from './PublicApiTokenSkeleton.module.scss';

export default function PublicApiTokenSkeleton() {
  return (
    <Stack
      direction="row"
      className={styles.container}
      data-testid="public-api-token-skeleton"
    >
      <Skeleton animation="wave" variant="circular" width="40px" height="40px">
        <Skeleton
          animation="wave"
          variant="circular"
          width="10px"
          height="10px"
        />
      </Skeleton>
      <Stack>
        <Skeleton
          animation="wave"
          variant="text"
          width="600px"
          className={styles.skeletonText}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width="600px"
          className={styles.skeletonText}
        />
        <Skeleton
          animation="wave"
          variant="text"
          width="300px"
          className={styles.skeletonText}
        />
      </Stack>

      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100px"
        height="36px"
        className={styles.skeletonButton}
      />
    </Stack>
  );
}
