import type { MutableRefObject } from 'react';

import { Divider } from '@mui/material';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { DotsVerticalIcon } from 'src/mui/_icons';

import { Dropdown } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import DeletedBillModal from 'src/features/bills-to-pay/DeletedBillModal';
import ImportScheduledEntryModal from 'src/features/entries/Modals/ImportScheduledEntryModal';

import styles from './ActionsButton.module.scss';

type Props = {
  apiRef: MutableRefObject<GridApiPremium>;
};

export default function ActionsButton({ apiRef }: Props) {
  const openPortal = useOpenPortal();

  const { organizationId, companyId } = useCompanyParams();

  const { accounts, isLoading: isLoadingAccounts } = useAccounts(
    {
      organizationId,
      companyId,
    },
    { staleTime: FIVE_MINUTES_IN_MS }
  );

  function handleExportCsv() {
    apiRef.current.exportDataAsCsv();
  }

  function handleOpenImportScheduledEntryModal() {
    openPortal(ImportScheduledEntryModal, {
      accounts,
      isLoadingAccounts,
    });
  }

  function handleOpenDeleted() {
    openPortal(DeletedBillModal);
  }

  return (
    <Dropdown
      className={styles.actions}
      aria-label="ações"
      variant="outlined"
      PopoverProps={{
        className: styles.popover,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      options={[
        <WithAllowedPermission
          permissions={{
            resource: 'entries',
            action: 'import_spreadsheet',
          }}
          key="uploadScheduled"
        >
          <Dropdown.Option onClick={handleOpenImportScheduledEntryModal}>
            Importar lançamentos programados (.xlsx)
          </Dropdown.Option>
        </WithAllowedPermission>,

        <Dropdown.Option onClick={handleExportCsv} key="createCsv">
          Exportar lançamentos (.csv)
        </Dropdown.Option>,

        <Divider key="drawersDivider" />,

        <WithAllowedPermission
          permissions={{
            resource: 'bills',
            action: 'delete_many',
          }}
          key="showDeleted"
        >
          <Dropdown.Option onClick={handleOpenDeleted} key="openDeletedDrawer">
            Ver excluídos
          </Dropdown.Option>
        </WithAllowedPermission>,
      ]}
    >
      <DotsVerticalIcon />
    </Dropdown>
  );
}
