import { useMemo } from 'react';

import type { GridColDef } from '@mui/x-data-grid-premium';
import { actionColumn, checkboxColumn } from 'src/mui/_scss/Table';

import type { Classification } from 'src/libs/finbits/Classifications/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import { notaFiscalNumberColumn } from 'src/features/entries/EntriesDataGrid/columns/notaFiscalNumberColumn/notaFiscalNumberColumn';
import { notaFiscalIssueDateColumn } from 'src/features/entries/EntriesDataGrid/columns/notaFiscalIssueDateColumn/notaFiscalIssueDateColumn';
import { dueDateColumn } from 'src/features/entries/EntriesDataGrid/columns/dueDateColumn/dueDateColumn';

import { RowActions } from './ContextMenuRowActions/RowActions/RowActions';
import { accountColumn } from './columns/accountColumn/accountColumn';
import { categoryColumn } from './columns/categoryColumn/categoryColumn';
import { contactColumn } from './columns/contactColumn/contactColumn';
import { currentSituationColumn } from './columns/currentSituationColumn/currentSituationColumn';
import { attachmentColumn } from './columns/attachmentColumn/attachmentColumn';
import { amountTypeColumn } from './columns/amountTypeColumn/amountTypeColumn';
import { relevantdateColumn } from './columns/relevantDateColumn/relevantDateColumn';
import { amountColumn } from './columns/amountColumn/amountColumn';
import { dateColumn } from './columns/dateColumn/dateColumn';
import { descriptionColumn } from './columns/descriptionColumn/descriptionColumn';
import { classificationsColumn } from './columns/classificationColumn/classificationColumn';
import { commentsColumn } from './columns/commentsColumn/commentsColumn';

function newBillFieldsColumns({
  canEdit,
  isNewBillFieldsEnabled,
}: {
  canEdit: boolean;
  isNewBillFieldsEnabled: boolean;
}) {
  if (isNewBillFieldsEnabled) {
    return [
      notaFiscalNumberColumn({ editable: canEdit }),
      notaFiscalIssueDateColumn({ editable: canEdit }),
      dueDateColumn({ editable: canEdit }),
    ];
  }
  return [];
}

export function useBuildColumns(
  classifications: Classification[],
  opts: { editable: boolean } = { editable: true }
): GridColDef[] {
  const canEdit =
    useAllowedPermission({
      action: 'batch_update',
      resource: 'entries',
    }) && opts.editable;

  const { isEnabled: isNewBillFieldsEnabled } = useExternalFeatureFlag(
    Feature.NEW_BILL_FIELDS
  );

  const columns = useMemo(() => {
    return [
      checkboxColumn(),
      dateColumn({ editable: canEdit }),
      descriptionColumn({ editable: canEdit }),
      accountColumn({ editable: canEdit }),
      currentSituationColumn(),
      categoryColumn({ editable: canEdit }),
      contactColumn({ editable: canEdit }),
      relevantdateColumn({ editable: canEdit }),
      ...classificationsColumn({
        classifications,
        columnProps: { editable: canEdit },
      }),
      amountColumn({ editable: canEdit }),
      amountTypeColumn(),
      attachmentColumn(),
      commentsColumn({ editable: canEdit }),
      actionColumn({
        getActions: RowActions,
      }),
      ...newBillFieldsColumns({ canEdit, isNewBillFieldsEnabled }),
    ];
  }, [classifications, canEdit, isNewBillFieldsEnabled]);

  return columns;
}
