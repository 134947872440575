import { type ReactNode, useMemo } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import cn from 'classnames';
import { Alert, AlertTitle, Typography } from 'src/design-system/components';
import { CloseIcon, LockKey } from 'src/mui/_icons';

import { Dialog } from 'src/mui';

import type { PortalProps } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import AccountDetails from './AccountDetails';
import styles from './AutoPaymentModal.module.scss';

type Props = PortalProps & {
  account: Account;
  bankTradeName: string;
  children: ReactNode;
  errorDescription?: string;
  errorTitle?: string;
  hasInvalidCredentials?: boolean;
  disableConfirmButton?: boolean;
  modalTitle?: string;
  onConfirmConnection: () => void;
};

export default function AutoPaymentModal({
  account,
  bankTradeName,
  children,
  errorDescription,
  errorTitle = 'A credencial informada está incorreta.',
  hasInvalidCredentials = false,
  disableConfirmButton = false,
  modalTitle = 'Conexão para pagamentos automáticos',
  onClose,
  onConfirmConnection,
  onExit,
  open = false,
}: Props) {
  const transitionProps: TransitionProps = useMemo(
    () => ({ direction: 'up', onExited: onExit }),
    [onExit]
  );

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={transitionProps}
      fullWidth
      maxWidth="xl"
      onClose={onClose}
    >
      <DialogTitle
        variant="text-md"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontWeight="600"
        color="grey.900"
      >
        {modalTitle}
        <IconButton
          aria-label="Fechar modal de conexão para pagamentos automáticos"
          onClick={onClose}
          className={styles.close}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.container} dividers>
        <AccountDetails account={account} />

        <div
          className={cn(styles.content, {
            [styles.gridAlert]: hasInvalidCredentials,
          })}
        >
          {hasInvalidCredentials && (
            <Alert severity="error" variant="standard">
              <AlertTitle>{errorTitle}</AlertTitle>
              {errorDescription}
            </Alert>
          )}

          <Box className={styles.cards}>{children}</Box>
        </div>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Box display="flex" alignItems="center" gap={3}>
          <LockKey color="primary" />

          <Typography color="grey.600">
            <strong>Conexão segura,</strong> desenvolvida em parceria com o{' '}
            <strong>{bankTradeName}.</strong>
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={3}>
          <Button size="medium" variant="outlined" onClick={onClose}>
            Fechar
          </Button>

          <Button
            size="medium"
            color="primary"
            variant="contained"
            disabled={disableConfirmButton}
            onClick={onConfirmConnection}
          >
            Confirmar conexão
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
