import { useState } from 'react';

import type { DatePickerSlotsComponentsProps } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import debounce from 'lodash/debounce';
import { CalendarIcon } from 'src/mui/_icons';

import { isValid } from 'src/libs/finbits/Date';

import styles from './ToolbarDatePicker.module.scss';

const changeDateWithDebounce = debounce(
  (range: Date, setRange: (date: Date) => void) => setRange(range),
  500
);

type Props = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

export function ToolbarDatePicker({ selectedDate, setSelectedDate }: Props) {
  const [value, setValue] = useState(selectedDate);
  const [open, setOpen] = useState(false);

  function handleDateChange(date: Date | null) {
    if (!!date && isValid(date)) {
      setValue(date);
      changeDateWithDebounce(date, setSelectedDate);
    }
  }

  const slotProps: DatePickerSlotsComponentsProps<Date> = {
    popper: { placement: 'bottom-start' },
    textField: {
      variant: 'standard',
      InputProps: {
        startAdornment: 'Até:',
        classes: {
          input: styles.input,
        },
        onClick: () => setOpen(true),
      },
      className: styles.datePicker,
    },
  };

  return (
    <DatePicker
      value={value}
      onChange={handleDateChange}
      format="dd/MM/yyyy"
      slotProps={slotProps}
      slots={{ openPickerIcon: CalendarIcon }}
      formatDensity="dense"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}
