import t from 'zod';

import { BankStatementScopes, PaymentConnectionScopes } from './enum';

export const OpenBankingConnectionFeaturesDecoder = t.object({
  accountId: t.string(),
  bankStatement: t
    .object({
      id: t.string(),
      scopes: t.array(t.nativeEnum(BankStatementScopes)).optional(),
    })
    .nullish(),
  paymentConnection: t
    .object({
      id: t.string(),
      scopes: t.array(t.nativeEnum(PaymentConnectionScopes)).optional(),
    })
    .nullish(),
});
