import { Divider } from '@mui/material';
import { DotsVerticalIcon } from 'src/mui/_icons';

import { Dropdown } from 'src/mui';

import type { Action } from 'src/features/bills-to-pay/Actions/type';

import styles from './DropdownActions.module.scss';

type Props = {
  actions: Action[];
};

export default function DropdownActions({ actions }: Props) {
  return (
    <Dropdown
      aria-label="mais ações"
      variant="outlined"
      PopoverProps={{
        classes: {
          paper: styles.dropdown,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      options={[
        actions.map((action, index) => {
          return (
            <div key={action.name}>
              {index !== 0 && <Divider />}

              <Dropdown.Option
                className={styles.option}
                onClick={action.onClick}
                key={action.name}
                icon={action.icon}
              >
                {action.label}
              </Dropdown.Option>
            </div>
          );
        }),
      ]}
    >
      <DotsVerticalIcon />
    </Dropdown>
  );
}
