import { Message } from 'src/mui/_scss';

import { Loader } from 'src/ui';

import noSuggestionsImg from 'src/assets/no-suggestions.svg';

import { useEntrySuggestionsContext } from 'src/features/entries/Suggestions/EntrySuggestionsProvider';

import SuggestionOrigin from './SuggestionOrigin/SuggestionOrigin';

export default function Suggestions() {
  const { suggestions, isLoadingSuggestions } = useEntrySuggestionsContext();

  if (isLoadingSuggestions && suggestions?.length === 0) {
    return <Loader />;
  }

  if (!isLoadingSuggestions && suggestions?.length === 0) {
    return (
      <Message
        title="Sem sugestões de preenchimento"
        subtitle="Com base nos valores e documentos inseridos neste lançamento, exibiremos
        aqui as sugestões de preenchimento automático para você utilizar."
        image={noSuggestionsImg}
        alt="sem sugestões"
        maxWidth={412}
      />
    );
  }

  return (
    <>
      {suggestions?.map((suggestion) => (
        <SuggestionOrigin key={suggestion.source.id} suggestion={suggestion} />
      ))}
    </>
  );
}
